import React, { useEffect } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import "../../../assets/css/custom.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import OldTabChart from "./oldTabChart.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import TabChart from "./tabChart.js";
import TabLog from "../BoitierLog/tabLog.js";
import OldTabLog from "../BoitierLog/oldTabLog.js";
import { isCaseOrDefaultClause } from "typescript";
export default function BoitierChart() {
  const nav = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      nav("/login");
    }
  }, [nav]);

  const params = useParams();
  const location = useLocation();
  const isOld = location.state?.isOld;
  const previousPage = () => {
    nav("/showBoitier/" + params.id, { state: { isOld } });
  };

  return (
    <>
      <div className="main-box">
        {/* <Sidebar /> */}
        <Row>
          <div className="content-wrapper-custom">
            <Button
              className="float-right"
              color="primary"
              onClick={previousPage}
              style={{ zIndex: "1" }}
            >
              Retour à la page précédente
            </Button>
            <Col lg="12">
              <Row className="CenterAll">
                <div className="containertitle">
                  <h2 className="titlePage">GRAPHIQUES</h2>
                </div>
              </Row>
              <Card>
                <CardBody>
                  {isOld === true ? <OldTabChart /> : <TabChart />}
                </CardBody>
              </Card>
            </Col>
          </div>
          <div className="content-wrapper-custom">
            <Col lg="12">
              <Row className="CenterAll">
                <div className="containertitle">
                  <h2 className="titlePage">LOGS DES CAPTEURS</h2>
                </div>
              </Row>
              <Card>
                <CardBody>
                  {isOld === true ? <OldTabLog /> : <TabLog />}
                </CardBody>
              </Card>
            </Col>
          </div>
        </Row>
      </div>
    </>
  );
}
