import react, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { FormGroup } from "reactstrap";
import { Form } from "reactstrap";
import { Input } from "reactstrap";
import { Container, Row, Col } from "reactstrap";
import LogoMain from "assets/img/logoacquagestblanc-small.png";
import "./auth.css";

export default function UpdatePassword() {
  const nav = useNavigate();

  const API_URL = "https://acquaback.shenron.dev/api/";

  const token = localStorage.getItem("token");

  //AXIOS CONFIGURATION
  let CONFIG_ = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const confirmPasswordError = document.getElementById(
    "confirm-password-error"
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      confirmPasswordError.textContent =
        "Le mot de passe et la confirmation du mot de passe ne correspondent pas.";
      return;
    } else {
      confirmPasswordError.textContent = "";
    }

    axios
      .post(
        API_URL + "updatePassword",
        {
          password,
        },
        CONFIG_
      )
      .then(function (response) {
        nav("/admin");
      })
      .catch(function (error) {});
  };

  return (
    <div className="height-login">
      <Row className="center-login">
        <Col className="background-login">
          <Form className="container-update-login" onSubmit={handleSubmit}>
            <img className=" logo-login" src={LogoMain} alt="logo-login"></img>
            <div className="label-passwordUp">
              Avant de pouvoir accéder à votre espace, merci de personnaliser
              votre mot de passe.
            </div>

            <FormGroup>
              <Input
                className="input-login"
                type="password"
                placeholder="Mot de passe"
                name="psw"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                className="input-login"
                type="password"
                placeholder="Confirmation de mot de passe"
                name="psw"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </FormGroup>
            <div id="confirm-password-error" className="confirm-error"></div>
            <Button className="" color="primary" type="submit">
              Changer le Mot de Passe
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
