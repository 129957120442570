import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  Row,
  Input,
} from "reactstrap";
import "../../../assets/css/custom.css";
import { useParams } from "react-router-dom";
import {
  OLD_API_URL,
  oldGetApiConfig,
  getOldMultipartConfig,
} from "../Config/oldApiConfig";
import DeletePng from "./../../../assets/png/multiply.png";

function ShowOldBoitierImage() {
  const params = useParams();

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null); // État pour l'aperçu de l'image

  const [modalGallery, setModalGallery] = useState(false);
  const toggleModalGallery = () => {
    setModalGallery(!modalGallery);
  };

  const [rapport, setRapport] = useState({
    id: null,
    time: "",
    dateActivation: "",
    commentaire: "",
    created_at: "",
    boitierId: null,
    technicien: null,
    photo: [],
  });

  const fetchImageUrls = () => {
    axios
      .get(OLD_API_URL + `boitier/${params.id}`, oldGetApiConfig())
      .then((res) => {
        // console.log(rapport, "rapport vierge");

        const fetchedRapport = res.data.data.rapport;
        fetchedRapport.commentaire = res.data.data.rapport.commentaire ?? "";
        setRapport(fetchedRapport);
        // console.log(fetchedRapport, "fetchedRapport");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchImageUrls();
  }, [params.id]);
  const handleAddImage = async (e) => {
    e.preventDefault();
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-"); // dd-mm-yyyy on formate bien la date en remplacant le / par -
    const formattedTime = date.toTimeString().split(" ")[0].slice(0, 5); // HH:mm

    try {
      const formData = new FormData();
      formData.append("technicien", 4);
      formData.append("photo[0]", selectedImage);
      formData.append("dateActivation", formattedDate);
      formData.append("time", formattedTime);
      formData.append("boitierId", params.id);
      formData.append("commentaire", rapport.commentaire); //changer le commentaire par un vrai commenaire
      console.log(selectedImage, "selectedImage");
      console.log("formData", formData);
      for (let pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      const response = await axios.post(
        OLD_API_URL + `rapport`,
        formData,
        getOldMultipartConfig()
      );

      // Mettez à jour l'état avec la nouvelle image
      setSelectedImage(null);
      setPreviewImage(null); // Réinitialiser l'aperçu de l'image
      fetchImageUrls(); // Réactiver le fetch des images
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result); // Mettre à jour l'URL de l'aperçu de l'image
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10%",
          right: "10%",
          left: "20%",
          zIndex: "999",
        }}
      >
        {alert ? <div>{alertContent}</div> : <></>}
      </div>
      <CardBody className="mt-4 rightContainer">
        <div className="text-center">
          <Button type="submit" color="primary" onClick={toggleModalGallery}>
            Voir les photos d'installations
          </Button>
        </div>
      </CardBody>

      <Modal
        isOpen={modalGallery}
        toggle={toggleModalGallery}
        className="custom-modal-gallery"
        size="lg"
      >
        <ModalHeader
          className="justify-content-center"
          toggle={toggleModalGallery}
        >
          Photo installation
        </ModalHeader>
        <ModalBody>
          <CardBody className="rightContainer">
            <Row className="d-flex justify-content-between mr-4 ml-3">
              <h4 className="titleCardContrast">Ajouter une Image:</h4>
            </Row>
            <div className="text-center">
              <form onSubmit={handleAddImage}>
                <Row>
                  <Input
                    color="primary"
                    type="file"
                    onChange={handleImageChange}
                  />
                  <Button color="primary" type="submit">
                    Valider
                  </Button>
                </Row>
              </form>
              {previewImage && ( // Afficher l'aperçu de l'image si elle est sélectionnée
                <div className="mt-4">
                  <img
                    src={previewImage}
                    alt="Aperçu"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              )}
            </div>
          </CardBody>
          <CardBody className="mt-4 rightContainer">
            <div className="custom-gallery scrollable-gallery">
              {rapport.photo.map((photo, index) => (
                <div key={index} className="custom-gallery-item">
                  <div className="image-container">
                    <img src={photo?.name} alt="gallery-item" />
                    {/* <button
                      className="delete-image-btn"
                      onClick={() => handleDeleteImage(photo)}
                    >
                      <img src={DeletePng} alt="Delete" />
                    </button> */}
                  </div>
                </div>
              ))}
            </div>
          </CardBody>
        </ModalBody>
      </Modal>
    </>
  );
}
export default React.memo(ShowOldBoitierImage);
