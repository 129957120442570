import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import { Button, Card, Col } from "reactstrap";
import { CircleLoader } from "react-spinners";
import { API_URL, getApiConfig } from "../Config/apiConfig";

export default function TabChart() {
  const params = useParams();
  const [chartData, setChartData] = useState([]);
  const [config, setConfig] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState("1h");
  const [sensors, setSensors] = useState(null);
  const setTimeFilter = (range) => {
    setTimeRange(range);
  };

  useEffect(() => {
    const getAllChartDataRequest = axios.get(
      `${API_URL}getAllChartData/${params.id}`,
      getApiConfig()
    );
    const getConfigRequest = axios.get(
      `${API_URL}getConfig/${params.id}`,
      getApiConfig()
    );
    setSensors(
      Object.keys(getAllChartDataRequest).map((sensor) => sensor.label)
    );

    axios.all([getAllChartDataRequest, getConfigRequest]).then(
      axios.spread((allChartDataResponse, configResponse) => {
        const allChartData = allChartDataResponse.data;
        const configData = configResponse.data;

        const now = new Date();
        let minRange;
        switch (timeRange) {
          case "1h":
            minRange = now.getTime() - 60 * 60 * 1000;
            break;
          case "24h":
            minRange = now.getTime() - 24 * 60 * 60 * 1000;
            break;
          case "1m":
            minRange = now.getTime() - 30 * 24 * 60 * 60 * 1000;
            break;
          case "6m":
            minRange = now.getTime() - 6 * 30 * 24 * 60 * 60 * 1000;
            break;
          case "1y":
            minRange = now.getTime() - 12 * 30 * 24 * 60 * 60 * 1000;
            break;
          case "total":
          default:
            minRange = null;
            break;
        }

        setChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            min: minRange,
            max: now.getTime(),
          },
        }));

        const formattedData = configData.map((sensor) => {
          const sensorData = allChartData.map((entry) => ({
            x: new Date(entry.epoch_date * 1000),
            y: entry[`capteur_${sensor.id}_data`],
          }));

          return {
            id: sensor.id,
            label: sensor.label,
            data: sensorData,
          };
        });

        setChartData(formattedData);
        setConfig(configData);
        setLoading(false);
      })
    );
  }, [params, timeRange]);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "area",
      height: 350,
      width: "100%", // Responsive width
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
    },
    xaxis: {
      type: "datetime",
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        colorStops: [
          {
            offset: 0,
            color: "#00E396",
            opacity: 0.7,
          },
          {
            offset: 100,
            color: "#0092FF",
            opacity: 0.9,
          },
        ],
      },
    },
  });

  return (
    <Col>
      <div className="btn-bar-chart">
        <Button onClick={() => setTimeFilter("1h")}>1 heure</Button>
        <Button onClick={() => setTimeFilter("24h")}>24 heures</Button>
        <Button onClick={() => setTimeFilter("1m")}>1 mois</Button>
        <Button onClick={() => setTimeFilter("6m")}>6 mois</Button>
        <Button onClick={() => setTimeFilter("1y")}>1 an</Button>
        <Button onClick={() => setTimeFilter("total")}>Total</Button>
      </div>
      {loading ? (
        <div className="circle-placeholder">
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircleLoader color={"#3B90C8"} />
          </div>
        </div>
      ) : (
        chartData.map((sensorData, index) => (
          <Card key={sensorData.id} className="chart-container">
            <h3 className="chart-label">
              {config[index]?.label || `Chart ${index + 1}`}
            </h3>
            <Chart
              series={[
                {
                  name: sensorData.label,
                  data: sensorData.data,
                },
              ]}
              options={chartOptions}
              type="area"
              width="100%" // Ajusté pour la responsivité
              height="450px"
            />
          </Card>
        ))
      )}
    </Col>
  );
}
