import React, { useState, useEffect } from "react";
import axios from "axios";
import boitier from "../../../assets/svg/boitier.svg";
import boitiervert from "../../../assets/svg/boitiervert.svg";
import boitiergris from "../../../assets/svg/boitiergris.svg";
import { Card, CardBody, Row, Col } from "reactstrap";
import { API_URL, getApiConfig } from "../Config/apiConfig.js";
import { OLD_API_URL, oldGetApiConfig } from "../Config/oldApiConfig";
import "../../../assets/css/custom.css";

export default function HeaderBoitiers(props) {
  //DATA
  const [activeInactiveBoitier, setActiveInactiveBoitier] = useState("");
  const [oldActiveInactiveBoitier, setOldActiveInactiveBoitier] = useState("");

  useEffect(() => {
    axios
      .get(API_URL + "countTotalActiveInactiveBoitier", getApiConfig())
      .then((res) => {
        setActiveInactiveBoitier(res);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    axios
      .get(OLD_API_URL + "boitier", oldGetApiConfig())
      .then((res) => {
        // console.log("meta de l'ancien boitier", res.data.meta);
        setOldActiveInactiveBoitier(res.data.meta);
      })
      .catch((err) => {});
  }, []);

  // Addition des résultats des deux sources
  const totalCount =
    (activeInactiveBoitier ? activeInactiveBoitier.data.totalCount : 0) +
    (oldActiveInactiveBoitier ? oldActiveInactiveBoitier.total : 0);

  const activeCount =
    (activeInactiveBoitier ? activeInactiveBoitier.data.activeCount : 0) +
    (oldActiveInactiveBoitier ? oldActiveInactiveBoitier.nb_actif : 0);

  const inactiveCount =
    (activeInactiveBoitier ? activeInactiveBoitier.data.inactiveCount : 0) +
    (oldActiveInactiveBoitier
      ? oldActiveInactiveBoitier.total - oldActiveInactiveBoitier.nb_actif
      : 0);
  return (
    <>
      <Row className="CenterAll">
        <Col lg="12">
          <div className="containertitle">
            <h2 className="titlePage">Boitiers </h2>
          </div>
        </Col>
      </Row>
      <Row className="CenterAll">
        <Col lg="12">
          <Card className="card-stats">
            <h3 className="titlePage2"> Informations générales</h3>
            <Row>
              <Col lg="4" md="6">
                <CardBody>
                  <Col className="headerCenter">
                    <img src={boitier} className="userImage" alt="React Logo" />
                  </Col>
                  <Col className="headerCenter">
                    <h3> Nombre de Boitiers</h3>
                  </Col>
                  <Col className="headerCenter">
                    <h3>{totalCount}</h3>
                  </Col>
                </CardBody>
              </Col>

              <Col lg="4" md="6">
                <CardBody>
                  <Col className="headerCenter">
                    <img
                      src={boitiervert}
                      className="userImage"
                      alt="React Logo"
                    />
                  </Col>
                  <Col className="headerCenter">
                    <h3>Boitiers actifs</h3>
                  </Col>
                  <Col className="headerCenter">
                    <h3>{activeCount}</h3>
                  </Col>
                </CardBody>
              </Col>

              <Col lg="4" md="6">
                <CardBody>
                  <Col className="headerCenter">
                    <img
                      src={boitiergris}
                      className="userImage"
                      alt="React Logo"
                    />
                  </Col>
                  <Col className="headerCenter">
                    <h3>Boitiers inactifs</h3>
                  </Col>
                  <Col className="headerCenter">
                    <h3>{inactiveCount}</h3>
                  </Col>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
