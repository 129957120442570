import React, { useState, useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import "../../../../assets/css/custom.css";
// import Clientsactifs from '../../../../assets/svg/Clientsactifs.svg';
// import GoogleMaps from '../../maps/GoogleMaps';
import Sidebar from "components/Sidebar/Sidebar.js";
import ShowClientTab from "./showClientTab";
import ShowClientHeader from "./showClientHeader";
import { useNavigate } from "react-router-dom";
// import Map from '../../../../assets/images/map.jpg';

export default function ShowClient() {
  // const ShowClient = (props) => {
  const nav = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      nav("/login");
    }
    // eslint-disable-next-line
  }, []);

  const [modalLarge, setModalLarge] = React.useState(false);

  const toggleModalLarge = () => {
    setModalLarge(!modalLarge);
  };

  //   const handleClick=  (id,country) =>{
  //     history.push('/admin/ShowBoitier', { id: id,country:country})
  //   }
  return (
    <>
      <div className="main-box">
        {/* <Sidebar /> */}
        <div className="content-wrapper-custom">
          <ShowClientHeader />
          <Col lg="12">
            {/* <Row className="CenterAll">
                    <div className="containertitle">
                        <h2 className="titlePage">INFORMATIONS GENERALES</h2>
                    </div>
                </Row> */}
            <Card>
              <CardBody>
                <ShowClientTab />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </>
  );
}
