import react, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../../assets/css/custom.css";
import Sidebar from "components/Sidebar/Sidebar.js";

import {
  Card,
  Row,
  CardBody,
  Col,
  CardFooter,
  CardHeader,
  Button,
  Input,
  // Row,
} from "reactstrap";

export default function ClientReglages() {
  const nav = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      nav("/login");
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="main-box">
        {/* <Sidebar /> */}
        <div className="content-wrapper-custom">
          <Row className="CenterAll">
            <div className="containertitle">
              <h2 className="titlePage">VERSIONS</h2>
            </div>
          </Row>
          <Row className="CenterAll">
            <Card>
              <table>
                <thead>
                  <tr className="version-settings-header-custom">
                    <th>
                      <h4>Nom de la version</h4>
                    </th>
                    <th>
                      <h4>Description</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Version 3.1</td>
                    <td>
                      <ul className="custom-reglages-dot">
                        Générateur A :<li>Courant : 250mA</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Générateur B :<li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Boost :<li>Allumé : 6 minutes</li>
                        <li>Éteint : 12 minutes</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Version 3.2</td>
                    <td>
                      <ul className="custom-reglages-dot">
                        Générateur A :<li>Courant : 500mA</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Générateur B :<li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Boost :<li>Allumé : 6 minutes</li>
                        <li>Éteint : 12 minutes</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Version 3.3</td>
                    <td>
                      <ul className="custom-reglages-dot">
                        Générateur A :<li>Courant : 1A</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Générateur B :<li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Pas de boost possible
                      </ul>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Version 3.4</td>
                    <td>
                      <ul className="custom-reglages-dot">Générateur A :
                        <li>Courant : 2A</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">Générateur B :
                        <li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">Boost :
                        <li>Allumé : 6 minutes</li>
                        <li>Éteint : 12 minutes</li>
                      </ul>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td>Version 3.5</td>
                    <td>
                      <ul className="custom-reglages-dot">Générateur A :
                        <li>Courant : 4A</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">Générateur B :
                        <li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">Pas de boost possible
                      </ul>
                    </td>
                  </tr> */}
                  <tr>
                    <td>Version 4.1</td>
                    <td>
                      <ul className="custom-reglages-dot">
                        Générateur A :<li>Courant : 500mA</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Générateur B :<li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Boost :<li>Allumé : 6 minutes</li>
                        <li>Éteint : 12 minutes</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Version 4.2</td>
                    <td>
                      <ul className="custom-reglages-dot">
                        Générateur A :<li>Courant : 1A</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Générateur B :<li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Boost :<li>Allumé : 6 minutes</li>
                        <li>Éteint : 12 minutes</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Version 4.3</td>
                    <td>
                      <ul className="custom-reglages-dot">
                        Générateur A :<li>Courant : 2A</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Générateur B :<li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Boost :<li>Allumé : 6 minutes</li>
                        <li>Éteint : 12 minutes</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Version 4.4</td>
                    <td>
                      <ul className="custom-reglages-dot">
                        Générateur A :<li>Courant : 4A</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Générateur B :<li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Boost :<li>Allumé : 6 minutes</li>
                        <li>Éteint : 12 minutes</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Version 4.5</td>
                    <td>
                      <ul className="custom-reglages-dot">
                        Générateur A :<li>Courant : 8A</li>
                        <li>Fréquences : 625Hz à 2.5Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Générateur B :<li>Courant : 1A</li>
                        <li>Fréquences : 2.5KHz à 10Khz</li>
                      </ul>
                      <ul className="custom-reglages-dot">
                        Pas de boost possible
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Row>
        </div>
      </div>
    </>
  );
}
