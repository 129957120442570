import React, { useState, useEffect } from "react";
import "react-table-v6/react-table.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import ReactTable from "react-table-v6";
import {
  API_URL,
  getApiConfig,
  getUserRoleId,
  token,
} from "../Config/apiConfig.js";
import {
  OLD_API_URL,
  oldGetApiConfig,
  oldToken,
} from "../Config/oldApiConfig.js";
import { arraysToCalcdata } from "plotly.js/lib/bar.js";

export default function TabBoitiers() {
  const nav = useNavigate();
  const [boitierList, setBoitierList] = useState([]);
  const userRoleId = getUserRoleId();
  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

  useEffect(() => {
    if (token) {
      axios
        .get(API_URL + "showBoitierList", getApiConfig())
        .then((res) => {
          setBoitierList(res.data);
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    if (oldToken) {
      axios
        .get(OLD_API_URL + "boitier/getBoitierList", oldGetApiConfig())
        .then((res) => {
          const ancienneData = res.data["data"];
          // console.log('ancienneData',ancienneData);
          ancienneData.forEach((boitier) => {
            boitier.versionVisible = `3.${boitier.versionVisible}`;
          });
          if (Array.isArray(ancienneData)) {
            setBoitierList((prevList) => [...prevList, ...ancienneData]);
          } else {
            console.error(
              "Les données de l'ancienne API ne sont pas un tableau :",
              ancienneData
            );
          }
        })
        .catch((err) => {
          console.error(
            "Une erreur s'est produite lors de la récupération des données de l'API ancienne:",
            err
          );
        });
    }
  }, []);

  const columns = [
    {
      Header: () => <div style={{ textAlign: "center" }}>NOM DU BOITIER</div>,
      accessor: (d) => d.rapport?.commentaire || d.comment || "no name",
      Cell: ({ value }) => {
        const FirstThirtyComment = value ? value.substring(0, 30) : "";
        return <div>{FirstThirtyComment}</div>;
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>N° DE SERIE</div>,
      accessor: (d) => d.noSerie || d.noSerie || "Erreur",
    },
    ...(userRoleId !== "5"
      ? [
          {
            Header: () => <div style={{ textAlign: "center" }}>CLIENT</div>,
            accessor: (d) => d.client?.nom || `${d.name} ${d.firstname}`,
          },
        ]
      : []),
    {
      Header: () => <div style={{ textAlign: "center" }}>STATUS</div>,
      accessor: (d) => d.state || d.statut,
      Cell: ({ value }) => {
        const etat =
          value == true ? "actif" : value == "actif" ? "actif" : "inactif";
        return (
          <div
            style={{
              color: etat === "actif" ? "green" : "red",
              textAlign: "center",
            }}
          >
            {etat}
          </div>
        );
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>VERSION</div>,
      accessor: (d) =>
        `${d.versionSoftware?.trim() || ""} ${d.versionVisible?.trim() || ""}`,
    },
  ].map((col, i) => {
    col.id = `${i}`;
    return col;
  });
  const onRowClick = (rowInfo) => {
    const isOld = rowInfo.noSerie >= 0 && rowInfo.noSerie <= 3500;
    nav(`/showBoitier/${rowInfo.id}`, { state: { isOld, id: rowInfo.id } });
  };

  return (
    <>
      <Row className="CenterAll">
        <Col lg="12">
          <Card className="mt-5">
            <div>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <ReactTable
                      filterable
                      getTrProps={(state, rowInfo) => ({
                        onClick: () => onRowClick(rowInfo.original),
                      })}
                      data={boitierList}
                      columns={columns}
                      defaultPageSize={10}
                      previousText={localization.previousText}
                      nextText={localization.nextText}
                      pageText={localization.pageText}
                      ofText={localization.ofText}
                      rowsText={localization.rowsText}
                    />
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
