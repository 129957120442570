import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  Row,
  Input,
} from "reactstrap";
import "../../../assets/css/custom.css";
import { useParams } from "react-router-dom";
import {
  API_URL,
  getApiConfig,
  getMultipartConfig,
} from "../Config/apiConfig.js";
import DeletePng from "./../../../assets/png/multiply.png";

export default function ShowBoitierImage() {
  const params = useParams();

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const [modalGallery, setModalGallery] = useState(false);
  const toggleModalGallery = () => {
    setModalGallery(!modalGallery);
  };

  useEffect(() => {
    const fetchImageUrls = () => {
      axios
        .get(API_URL + `showRapportImage/${params.id}`, getApiConfig())
        .then((res) => {
          setImageUrls(res.data.urls);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    fetchImageUrls();
    // eslint-disable-next-line
  }, []);

  function handleImageChange(event) {
    setSelectedImage(event.target.files[0]);
  }

  const handleAddImage = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("image", selectedImage);
      const response = await axios.post(
        API_URL + `addRapportImage/${params.id}`,
        formData,
        getMultipartConfig()
      );
      const imageUrl = response.data.name; // Assuming the URL is present in the "name" field of the response object
      const fullImageUrl = `${API_URL}image/${params.id}/${imageUrl}`; // Combine the URL with the API_URL
      setImageUrls((prevUrls) => [...prevUrls, fullImageUrl]);
      setSelectedImage(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteImage = async (url) => {
    try {
      const filename = url.substring(url.lastIndexOf("/") + 1);
      await axios.delete(
        API_URL + `deleteRapportImage/${params.id}/${filename}`,
        getApiConfig()
      );
      setImageUrls((prevUrls) => prevUrls.filter((prevUrl) => prevUrl !== url));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10%",
          right: "10%",
          left: "20%",
          zIndex: "999",
        }}
      >
        {alert ? <div>{alertContent}</div> : <></>}
      </div>
      <CardBody className="mt-4 rightContainer">
        <div className="text-center">
          <Button type="submit" color="primary" onClick={toggleModalGallery}>
            Voir les photos d'installations
          </Button>
        </div>
      </CardBody>

      <Modal
        isOpen={modalGallery}
        toggle={toggleModalGallery}
        className="custom-modal-gallery"
        size="lg"
      >
        <ModalHeader
          className="justify-content-center"
          toggle={toggleModalGallery}
        >
          <h4>Photo installation</h4>
        </ModalHeader>
        <ModalBody>
          <CardBody className="rightContainer">
            <Row className="d-flex justify-content-between mr-4 ml-3">
              <h4 className="titleCardContrast">Ajouter une Image:</h4>
            </Row>
            <div className="text-center">
              <form onSubmit={handleAddImage}>
                <Row>
                  <Input
                    color="primary"
                    type="file"
                    onChange={handleImageChange}
                  />
                  <Button color="primary" type="submit">
                    Valider
                  </Button>
                </Row>
              </form>
            </div>
          </CardBody>
          <CardBody className="mt-4 rightContainer">
            <div className="custom-gallery scrollable-gallery">
              {imageUrls.map((url, index) => (
                <div key={index} className="custom-gallery-item">
                  <div className="image-container">
                    <img src={url} alt="gallery-item" />
                    <button
                      className="delete-image-btn"
                      onClick={() => handleDeleteImage(url)}
                    >
                      <img src={DeletePng} alt="Delete" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </CardBody>
        </ModalBody>
      </Modal>
    </>
  );
}
