import React, { useState } from 'react';
import UserAddForm from './userAddForm';
import { useParams } from 'react-router-dom';

export default function UserAdd() {
    const params = useParams();

    return (
        <div>
            <UserAddForm role_id={params.id} />
        </div>
    );
}