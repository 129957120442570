import react, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { FormGroup } from "reactstrap";
import { Form } from "reactstrap";
import { Input } from "reactstrap";
import { Row } from "reactstrap";
import LogoMain from "assets/img/logoacquagestblanc-small.png";
import "./auth.css";

let newToken = "";

export default function Login({ setUserRole }) {
  const nav = useNavigate();
  const API_URL = "https://acquaback.shenron.dev/api/";
  let CONFIG_ = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const [formVisible, setFormVisible] = useState(false);

  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  };

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [role, setRole] = useState();
  const loginError = document.getElementById("login-error");
  const resetError = document.getElementById("reset-error");
  const resetSuccess = document.getElementById("reset-success");

  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post(
        API_URL + "login",
        {
          email,
          password,
        },
        CONFIG_
      )
      .then(function (response) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user_params_id", response.data.user.id);
        localStorage.setItem("user_role_id", response.data.user.role_id);
        localStorage.setItem("loggedUser", JSON.stringify(response.data.user));

        newToken = response.data.token;

        if (response.data.user.password_changed === 1) {
          const userRoleId = localStorage.getItem("user_role_id");
          setUserRole(parseInt(userRoleId));

          nav("/Dashboard");
          // if (response.data.user.role_id === 1) {
          //   nav('/Dashboard');
          // }
          // else if (response.data.user.role_id === 2) {
          //   nav('/DistribBoard');
          // }
          // else if (response.data.user.role_id === 3) {
          //   nav('/ResellBoard');
          // }
          // else if (response.data.user.role_id === 4) {
          //   nav('/TechBoard');
          // }
          // else if (response.data.user.role_id === 5) {
          //   nav('/ClientBoard');
          // }
          // else {
          //   alert('403 - roleid');
          // }
        } else {
          nav("/updatePassword");
        }
      })
      .catch(function (error) {
        if (error.message === "Request failed with status code 401") {
          loginError.textContent = "Email ou mot de passe incorrect";
        }
      });

    try {
      const response = await fetch(
        "https://api-acquagest.shenron.dev/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            email: email,
            password: password,
            device_name: "react-spa",
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        const token = data.token;
        localStorage.setItem("Oldtoken", token);
        console.log("old token attribué");
      } else {
        throw new Error("Erreur lors de la connexion");
      }
    } catch (error) {
      console.error(error.message, "old token n'a pas été attribué");
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    axios
      .post(
        API_URL + "forgot-password",
        {
          email,
        },
        CONFIG_
      )
      .then(function (response) {
        if (
          response.data.message === "Reset code has been sent to your email!"
        ) {
          resetSuccess.textContent =
            "Le lien de réinitialisation a bien été envoyé, vérifiez vos spam.";
        }
      })
      .catch(function (error) {
        if (error.message === "Request failed with status code 404") {
          resetError.textContent = "Cet email n'est pas reconnu.";
        }
      });
  };

  return (
    <div className="height-login">
      <Row className="center-login">
        <div className="background-login">
          {!formVisible ? (
            <Form className="container-login" onSubmit={handleSubmit}>
              <img className="logo-login" src={LogoMain} alt="logo-login"></img>
              <div className="label-passwordUp">
                Connectez vous à l'outil acquagest :
              </div>
              <FormGroup>
                <Input
                  className=" input-login"
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  className=" input-login"
                  type="password"
                  placeholder="Mot de passe"
                  name="psw"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FormGroup>
              <div id="login-error" className="confirm-error"></div>
              <Button className="" color="primary" type="submit">
                Se connecter
              </Button>
              <div
                className="label-passwordUp clickable-link"
                onClick={handleToggleForm}
              >
                Mot de passe oublié ?
              </div>
            </Form>
          ) : (
            <Form className="container-login" onSubmit={handleForgotPassword}>
              <img className="logo-login" src={LogoMain} alt="logo-login"></img>
              <div className="label-passwordUp">
                Réinitialisez votre mot de passe :
              </div>
              <FormGroup>
                <Input
                  className="input-login"
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <div id="reset-success" className="confirm-success"></div>
              <div id="reset-error" className="confirm-error"></div>
              <Button className="" color="primary" type="submit">
                Réinitialiser
              </Button>
              <div
                className="label-passwordUp clickable-link"
                onClick={handleToggleForm}
              >
                Retour à la connexion
              </div>
            </Form>
          )}
        </div>
      </Row>
    </div>
  );
}

export { newToken };
