import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Card,CardBody,Row,Button} from "reactstrap";
import { useParams } from 'react-router-dom';
import '../../../assets/css/custom.css';
import { Alert } from "reactstrap";
import { API_URL, getApiConfig, getUserRoleId } from '../Config/apiConfig.js';


export default function ShowBoitierAssign({ user_id, customer_Name }) { 

    const params = useParams();

    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        setUserId(user_id);
    }, [user_id]);

    useEffect(() => {
        setCustomerName(customer_Name);
    }, [customer_Name]);
    
    const [customerName, setCustomerName] = useState('');
    const [userId, setUserId] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');

    const [assignVisible, setAssignVisible] = useState(false);
    const [assignSubmitted, setAssignSubmitted] = useState(false);

    const handleToggleAssign = () => {
        setAssignVisible(!assignVisible);
      }

    const handleSubmitForAssignedUser = async e => {
        e.preventDefault();
        axios.post(API_URL + `updateCustomerName/${params.id}`, { user_id : userId }, getApiConfig())
        .then(function (response) {
            if(response.data === 'success')
            {
              setAlertContent(<Alert color="success">Les données ont bien été enregistré !</Alert>);
              setAlert(true);
              const timeId = setTimeout(() => {
                setAlert(false)
              }, 3000);
            } else {
              setAlertContent(<Alert color="danger">Une erreur est survenue, veuillez réessayer</Alert>);
              setAlert(true);
              const timeId = setTimeout(() => {
                setAlert(false)
              }, 3000);
            }
         }).catch(error=>{
            alert(error)
         });

         setAssignSubmitted(false);
         setAssignVisible(false);
    }

    useEffect(() => {
        const fetchUserRole = async () => {
            const role = await getUserRoleId();
            setUserRole(role);
        };
    
        fetchUserRole();
    }, []);
    
  
    useEffect(() => {
        axios.get(API_URL + 'showCustomerListForAssign', getApiConfig())
          .then(res => {
            setCustomerList(res.data);
          })
          .catch(err => {
          });
      }, []);

    const [customerList, setCustomerList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    useEffect(() => {
        const results = customerList.filter(
          (customer) =>
            customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            customer.firstname.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(results);
      }, [searchTerm, customerList]);
    
      const handleSearch = (event) => {
        setSearchTerm(event.target.value);
      };

      const handleSelectCustomer = (customer) => {
        setSelectedCustomer(customer);
        setUserId(customer.id);
        setSearchResults([]);
        setSearchTerm(`${customer.name} ${customer.firstname}`);
        setCustomerName(`${customer.name} ${customer.firstname}`);
      };
 
  return (
    <>

    <div style={{ position: 'fixed', top: '10%', right: '10%', left: '20%', zIndex: '999' }}>
        {alert ? <div>{alertContent}</div> : <></> }
    </div>

    {!assignVisible || assignSubmitted ? (
        <div>
            <Card  className="cardContainer">
                <CardBody >
                    <div className="containerTextCard">
                        <h4  className="titleCard ">
                        CLIENT ASSIGNE
                        </h4>
                        <div style={{ borderTop: "0.5px solid #fff" , marginBottom:'20px'}}></div>
                        <Card className="rowContainer">
                            <Row>
                                <div className="titleRow" >
                                Client
                                </div>
                                <div className="textRow" >{customerName}</div>
                            </Row>
                        </Card>
                    </div>
                    <div className="text-center">
                        {userRole && userRole <= 3 && (
                            <Button color="primary" onClick={handleToggleAssign}>Editer</Button>
                        )}
                    </div>
                </CardBody>
            </Card>
        </div>
    ) : (
        <div>  
            <form onSubmit={handleSubmitForAssignedUser}>
                <Card  className="cardContainer">
                    <CardBody >
                        <div className="containerTextCard">
                            <h4  className="titleCard">
                            CLIENT ASSIGNE
                            </h4>
                            <div style={{ borderTop: "0.5px solid #fff" , marginBottom:'20px'}}></div>
                            <Card className="rowContainer">
                                <Row>
                                    <div className="titleRow">Client</div>
                                    <div className="textRow">
                                        <input className="" id="custom-input-row-noheight" autocomplete="off" type="search" value={searchTerm} onChange={handleSearch} />
                                        {searchTerm && (
                                            <div className="custom-search-result" onClick={() => setSearchResults([])}>
                                                <ul>
                                                    {searchResults.map((customer) => (
                                                        <li
                                                        key={customer.id}
                                                        className={selectedCustomer && selectedCustomer.id === customer.id ? "selected" : ""}
                                                        onClick={() => handleSelectCustomer(customer)}
                                                        >
                                                        {customer.name} {customer.firstname}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </Row>
                            </Card>
                        </div>
                        <div className="text-center">
                            <Button type="submit" color="primary">Enregistrer</Button>
                        </div>
                    </CardBody>
                </Card>
            </form>
        </div>
    )}
    </>
  );
};
