import React, { useState, useEffect } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { Col } from "reactstrap";
import "../../../assets/css/custom.css";
import BoitiersId1 from "./boitiersId1.js";
import OldBoitiersId1 from "./oldBoitiersId1.js";
import ShowBoitierHeader from "./showBoitierHeader.js";
import ShowOldBoitierHeader from "./ShowOldBoitierHeader.js";
import AlertList from "./alertList.js";
import { getUserRoleId } from "../Config/apiConfig.js";
import { OLD_API_URL, oldGetApiConfig } from "../Config/oldApiConfig";
import { API_URL, getApiConfig } from "../Config/apiConfig.js";
import axios from 'axios';

export default function ShowBoitier() {
  const role = getUserRoleId();
  const location = useLocation();
  const params = useParams();
  const [boitierFound, setBoitierFound] = useState(true); // Nouvel état pour gérer l'erreur
  const [boitierType, setBoitierType] = useState(null); // État pour gérer le type de boîtier
  const [boitierData, setBoitierData] = useState(null); // État pour stocker les données du boîtier

  useEffect(() => {
    const checkBoitier = async () => {
      const id = parseInt(params.id, 10); // Assurez-vous que l'ID est bien un nombre
      try {
        if (id < 4000) {
          const data = await fetchOldBoitier(id);
          setBoitierType("old");
          setBoitierData(data);
        } else {
          const data = await fetchNewBoitier(id);
          setBoitierType("new");
          setBoitierData(data);
        }
      } catch (error) {
        setBoitierFound(false);
      }
    };
    checkBoitier();
  }, [params.id]);

  return (
    <>
      <div className="main-box">
        <div className="content-wrapper-custom">
          {boitierFound ? (
            <>
              {boitierType === "old" ? <ShowOldBoitierHeader /> : <ShowBoitierHeader />}
              <Col lg="12">
                {boitierType === "old" ? <OldBoitiersId1 data={boitierData} /> : <BoitiersId1 data={boitierData} />}
              </Col>
              <Col lg="12">{role === 1 ? <AlertList /> : null}</Col>
            </>
          ) : (
            <div className="error-message">Erreur: Boîtier introuvable</div>
          )}
        </div>
      </div>
    </>
  );
}

const fetchOldBoitier = async (id) => {
  return axios
    .get(OLD_API_URL + `boitier/${id}`, oldGetApiConfig())
    .then((res) => {
      if (res.data && res.data.data) {
        return res.data.data;
      } else {
        throw new Error("Boîtier introuvable");
      }
    })
    .catch((error) => {
      console.error(error);
      throw new Error("Boîtier introuvable");
    });
};

const fetchNewBoitier = async (id) => {
  return axios
    .get(API_URL + `showBoitier/${id}`, getApiConfig())
    .then((res) => {
      console.log(res)
      if (res.data && res.data?.id) {
        return res.data.data;
      } else {
        throw new Error("Boîtier introuvable");
      }
    })
    .catch((error) => {
      console.error(error);
      throw new Error("Boîtier introuvable");
    });
};
