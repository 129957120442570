// Importation des dépendances nécessaires
import React, { useState, useEffect } from "react";
import {
  CardBody,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import axios from "axios";
import "../../../assets/css/custom.css";
import {
  oldGetUserRoleId,
  OLD_API_URL,
  oldGetApiConfig,
} from "../Config/oldApiConfig";
import { useParams } from "react-router-dom";
// Définition du composant ShowOldBoitierComment
export default function ShowOldBoitierComment({
  comment,
  setComment,
  boitierId,
  rapportId,
}) {
  const params = useParams();

  // Définition des états locaux
  const [modalComment, setModalComment] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  // Fonction pour basculer l'affichage de la modal
  const toggleModalComment = () => setModalComment(!modalComment);
  const userRoleId = oldGetUserRoleId();

  // Utilisation de useEffect pour récupérer l'URL de l'image lors du montage du composant
  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        const response = await axios.get(
          `${OLD_API_URL}boitier/${boitierId}`,
          oldGetApiConfig()
        );
        setImageUrl(response.data.data.rapport.photo?.[0]?.name || "");
      } catch (error) {
        console.error("Error fetching image URL:", error);
      }
    };
    fetchImageUrl();
  }, [boitierId]);

  // Fonction pour gérer l'ajout d'une image
  const handleAddImage = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Création d'un objet payload pour stocker les données à envoyer
    const payload = {
      commentaire: comment || "", // Assurez-vous que cette valeur n'est pas nulle
    };

    // Création d'un objet FormData et ajout des données du payload
    const formData = new FormData();
    for (let key in payload) {
      if (payload[key]) {
        formData.append(key, payload[key]);
        console.log("shenron key:", key, "value:", payload[key]);
      }
    }

    // Tentative d'envoi des données
    try {
      const response = await axios.put(
        `${OLD_API_URL}rapport/${rapportId}`,
        formData,
        oldGetApiConfig()
      );
      console.log("Comment and image submitted successfully:", response.data);
      toggleModalComment();
    } catch (error) {
      console.error("Error submitting comment and image:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Rendu du composant
  return (
    <>
      <Modal isOpen={modalComment} toggle={toggleModalComment} size="lg">
        <ModalHeader
          className="justify-content-center"
          toggle={toggleModalComment}
        >
          Ajouter des informations complémentaires
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleAddImage}>
            <Input
              value={comment}
              className="heighTextArea pl-3"
              type="textarea"
              cols="50"
              rows="15"
              name="text"
              id="exampleText"
              placeholder="Informations complémentaires..."
              onChange={(e) => setComment(e.target.value)}
            />
            <Button
              className="float-right mt-3 mr-5"
              type="submit"
              color="primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Ajout en cours..." : "Ajouter"}
            </Button>
          </form>
        </ModalBody>
      </Modal>
      <CardBody className="rightContainer">
        <Row className="d-flex justify-content-between mr-4 ml-3">
          <h4 className="titleCardContrast">Informations complémentaires</h4>
          <div
            style={{ borderTop: "0.5px solid #fff", marginBottom: "20px" }}
          ></div>
          {userRoleId !== 5 && (
            <Button
              className="btn-round btn-icon"
              color="primary"
              onClick={toggleModalComment}
            >
              <i className="tim-icons icon-simple-add text-white" />
            </Button>
          )}
        </Row>
        <div className="mt-4 pb-3 mr-4 ml-4">{comment}</div>
      </CardBody>
    </>
  );
}
