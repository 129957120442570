import React, { useState, useEffect } from "react";
import "../../../assets/css/custom.css";
import HeaderDashboard from "./headerDashboard";
import TabDashboard from "./tabDashboard";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {

  return (
    <>
      <div className="main-box">
        {/* <Sidebar /> */}
        <div className="content-wrapper-custom">
          <HeaderDashboard />
          <TabDashboard />
        </div>
      </div>
    </>
  );
}
