import React, { useState, useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import "../../../../assets/css/custom.css";
// import Clientsactifs from '../../../../assets/svg/Clientsactifs.svg';
// import GoogleMaps from '../../maps/GoogleMaps';
// import { NavLink } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";
import ShowDistributeurHeader from "./showDistributeurHeader.js";
import ShowDistributeurTab from "./showDistributeurTab.js";
import { useNavigate } from "react-router-dom";
// import Map from '../../../../assets/images/map.jpg';

export default function ShowDistributeur() {
  const nav = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      nav("/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="main-box">
        {/* <Sidebar /> */}
        <div className="content-wrapper-custom">
          <ShowDistributeurHeader />
          <Col lg="12">
            <Card>
              <CardBody>
                <ShowDistributeurTab />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </>
  );
}
