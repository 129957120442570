import React, { useState, useEffect } from "react";
import "../../../assets/css/custom.css";
import UserListHeader from "./userListHeader";
import UserListTab from "./userListTab";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";

export default function UserList() {
  const params = useParams();
  const nav = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      nav("/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="main-box">
        {/* <Sidebar /> */}
        <div className="content-wrapper-custom">
          <UserListHeader role_id={params.id} />
          <UserListTab role_id={params.id} />
        </div>
      </div>
    </>
  );
}
