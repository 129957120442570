import react, { useState, useEffect } from "react";
import Switch from "react-bootstrap-switch";
import axios from "axios";
import "../../../assets/css/custom.css";
import { Alert } from "reactstrap";

import { Card, Row, CardBody } from "reactstrap";
import { getApiConfig, API_URL } from "../Config/apiConfig";
export default function AlertList() {
  const [alertList, setAlertList] = useState();

  // const params = useParams();

  useEffect(() => {
    axios
      .get(API_URL + "showAlertList", getApiConfig())
      .then((res) => {
        setAlertList(res.data);
      })
      .catch((err) => {});
  }, []);

  // VISUAL ALERT
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const [isVisible, setIsVisible] = useState();
  const [mailed, setMailed] = useState();

  function handleSwitchChangeVisible(id, isVisible) {
    axios
      .post(
        API_URL + `updateAlertList/${id}`,
        {
          isVisible: !isVisible,
        },
        getApiConfig()
      )
      .then(function (response) {
        if (response.data === "success") {
          setAlertContent(
            <Alert color="success">Les données ont bien été enregistré !</Alert>
          );
          setAlert(true);
          const timeId = setTimeout(() => {
            setAlert(false);
          }, 3000);
          let newAlertList = [...alertList];
          newAlertList = newAlertList.map((item) => {
            if (item.id === id) {
              return { ...item, isVisible: !isVisible };
            }
            return item;
          });
          setAlertList(newAlertList);
        } else {
          setAlertContent(
            <Alert color="danger">
              Une erreur est survenue, veuillez réessayer
            </Alert>
          );
          setAlert(true);
          const timeId = setTimeout(() => {
            setAlert(false);
          }, 3000);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function handleSwitchChangeMailed(id, mailed) {
    axios
      .post(
        API_URL + `updateAlertList/${id}`,
        {
          mailed: !mailed,
        },
        getApiConfig()
      )
      .then(function (response) {
        if (response.data === "success") {
          setAlertContent(
            <Alert color="success">Les données ont bien été enregistré !</Alert>
          );
          setAlert(true);
          const timeId = setTimeout(() => {
            setAlert(false);
          }, 3000);
          let newAlertList = [...alertList];
          newAlertList = newAlertList.map((item) => {
            if (item.id === id) {
              return { ...item, mailed: !mailed };
            }
            return item;
          });
          setAlertList(newAlertList);
        } else {
          setAlertContent(
            <Alert color="danger">
              Une erreur est survenue, veuillez réessayer
            </Alert>
          );
          setAlert(true);
          const timeId = setTimeout(() => {
            setAlert(false);
          }, 3000);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10%",
          right: "10%",
          left: "20%",
          zIndex: "999",
        }}
      >
        {alert ? <div>{alertContent}</div> : <></>}
      </div>

      <Row className="CenterAll">
        <div className="containertitle">
          <h2 className="titlePage">ALERTES</h2>
        </div>
      </Row>
      <Row className="CenterAll">
        <Card>
          <CardBody>
            {alertList?.map((item) => {
              return (
                <div className="d-flex justify-content-between">
                  <CardBody className="alert-list-custom">
                    <div className="margin-list-custom">
                      <div>
                        <div className="float-right ml-3">
                          <p>Visible par l'utilisateur</p>
                          <Switch
                            value={item.isVisible}
                            onColor="green"
                            offColor="red"
                            onChange={() =>
                              handleSwitchChangeVisible(item.id, item.isVisible)
                            }
                          />
                        </div>
                        <div className="float-right">
                          <p>Notification par e-mail</p>
                          <Switch
                            value={item.mailed}
                            onColor="green"
                            offColor="red"
                            onChange={() =>
                              handleSwitchChangeMailed(item.id, item.mailed)
                            }
                          />
                        </div>
                      </div>
                      <div className="wrap-list-custom">
                        <h4>{item.title}</h4>
                      </div>
                    </div>
                  </CardBody>
                </div>
              );
            })}
          </CardBody>
        </Card>
      </Row>
    </>
  );
}
