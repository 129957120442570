const API_URL = "https://acquaback.shenron.dev/api/";
const token = localStorage.getItem("token");

function getApiConfig() {
  const token = localStorage.getItem("token");

  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
}

function getMultipartConfig() {
  const token = localStorage.getItem("token");

  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
}

function getUserRoleId() {
  return localStorage.getItem("user_role_id");
}

export { API_URL, token, getApiConfig, getMultipartConfig, getUserRoleId };
