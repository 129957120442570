import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardBody, Button } from "reactstrap";
import "../../../assets/css/custom.css";
import { useNavigate, useParams } from "react-router-dom";
import { getUserRoleId } from "../Config/apiConfig.js";
import { OLD_API_URL, oldGetApiConfig } from "../Config/oldApiConfig";

export default function OldBoitierLastAlert() {
  const nav = useNavigate();
  const params = useParams();
  const roleId = getUserRoleId();

  const [lastAlert, setLastAlert] = useState();
  useEffect(() => {
    axios
      .get(OLD_API_URL + `boitier/${params.id}`, oldGetApiConfig())
      .then((res) => {
        const filteredAlerts = res.data.data.journal
          .filter((alert) => {
            const display = shouldDisplayAlert(alert.status.content);
            return display;
          })
          .reverse()
          .slice(0, 3); // Prendre les 3 premières alertes
        setLastAlert(filteredAlerts);
        // console.log(filteredAlerts, "filteredAlerts");
      })
      .catch((err) => {
        console.error(err);
      });
    // eslint-disable-next-line
  }, [params.id]);

  const addParamToUrlForAlert = () => {
    nav("/oldBoitierAlert/" + params.id);
  };

  const shouldDisplayAlert = (alert) => {
    const alertsVisibility = {
      "Données entrantes": ["null"], //noBody needs to see this
      "Perte de connexion": [1, 2, 3, 4, 5],
      "Retour de connexion": [1, 2, 3, 4, 5],
      "Nouveau boîtier a été créé": [1, 2, 3, 4, 5],
      "Redémarrage du boîtier": [1, 2, 3, 4, 5],
      "Intervention réalisé": [1, 2, 3, 4, 5],
      "Changement d’information dans la base (position, client)": [1, 2, 3, 4],
      "Température trop haute": [1, 2, 3, 4, 5],
    };

    return alertsVisibility[alert]?.includes(parseInt(roleId)) ?? true;
  };

  const getAlertColorClass = (title) => {
    const colorClassMap = {
      "Données entrantes": "circle_form_blue",
      "Perte de connexion": "circle_form_orange",
      "Retour de connexion": "circle_form_blue",
      "Nouveau boîtier a été créé": "circle_form_blue",
      "Redémarrage du boîtier": "circle_form_blue",
      "Intervention réalisé": "circle_form_blue",
      "Changement d’information dans la base (position, client)":
        "circle_form_blue",
      "Température trop haute": "circle_form_red",
    };
    // console.log(title, "title", colorClassMap[title]);

    return colorClassMap[title] || "circle_form_red";
  };

  const getStatusContentById = (statusId) => {
    // Mapper les identifiants de statut aux contenus correspondants
    const statusContentMap = {
      1: "Données entrantes",
      2: "Perte de connexion",
      3: "Retour de connexion",
      4: "Nouveau boîtier a été créé",
      5: "Redémarrage du boîtier",
      6: "Intervention réalisée",
      7: "Changement d’information dans la base (position, client)",
      8: "Température trop haute",
    };

    // Récupérer le contenu du statut à partir de son identifiant
    return statusContentMap[statusId] || "Statut inconnu";
  };
  const formattedDate = (content) => {
    const date = new Date(content);
    const datePart = date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const timePart = date.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return `${datePart} ${timePart}`;
  };

  return (
    <>
      <CardBody className="mt-4 rightContainer">
        {lastAlert &&
          lastAlert.length > 0 &&
          lastAlert.map((alert, index) => (
            <Card
              key={index}
              className="shadow mb-5 bg-white rounded card-hover"
            >
              <CardBody>
                <div className="ml-5 mr-5 d-flex">
                  <div
                    className={`${getAlertColorClass(
                      alert.status.content
                    )} mr-4 flex-shrink-0`}
                  ></div>
                  <div className="d-flex flex-column">
                    {alert.unite === "Booléen" ? (
                      <div>{alert.boitier_journal_status_id}</div>
                    ) : (
                      <div>
                        {getStatusContentById(alert.boitier_journal_status_id)}
                      </div>
                    )}
                  </div>
                </div>
                <div className="float-right">
                  <div>
                    {alert && alert?.created_at
                      ? formattedDate(alert.created_at)
                      : ""}
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}
        <div className="text-center">
          <Button type="submit" color="primary" onClick={addParamToUrlForAlert}>
            Voir plus d'alertes
          </Button>
        </div>
      </CardBody>
    </>
  );
}

/*           const test = res.data.data.journal;
          let journal = []
          test.forEach(element => {
            journal = [...journal, element.status.content]
          });
          console.log(journal, "journal")
          */
