import React from "react";
import "../../../assets/css/custom.css";
import HeaderBoitiers from "./headerBoitiers";
import TabBoitiers from "./tabBoitiers";
// import { useNavigate } from 'react-router-dom'
import Sidebar from "components/Sidebar/Sidebar.js";

const Boitiers = () => {
  return (
    <>
      <div className="main-box">
        {/* <Sidebar /> */}
        <div className="content-wrapper-custom">
          <HeaderBoitiers />
          <TabBoitiers />
        </div>
      </div>
    </>
  );
};

export default Boitiers;
