import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactTable from "react-table-v6";
import { CircleLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { OLD_API_URL, oldGetApiConfig } from "../Config/oldApiConfig";
import "../../../assets/css/custom.css";
import { Button, Card, Row, Col, CardBody } from "reactstrap";

export default function TabLog() {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [circleLoading, setCircleLoading] = useState(true);
  const [separatedData, setSeparatedData] = useState([]);

  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

  const headData = [
    "DATE",
    "bobine A",
    "bobine B",
    "générateur A",
    "générateur B",
    "Temp",
    "capteur 1",
    "capteur 2",
    "capteur 3",
    "capteur 4",
    "capteur 5",
    "capteur 6",
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${OLD_API_URL}boitier/${params.id}`,
          oldGetApiConfig()
        );
        const getAllChartDataRequest = response.data.data.journal;
        const regex = /^(\d{2}\/){6}(\d{1}\/){5}(\d{4}\/){7}AA$/;
        let data = getAllChartDataRequest.map((log) => {
          if (!regex.test(log.content)) {
            return null;
          }
          let values = log.content.split("/");
          let dateSplited = values.slice(0, 6);
          let formattedDate = `${dateSplited[0]}/${dateSplited[1]}/${dateSplited[2]} ${dateSplited[3]}:${dateSplited[4]}:${dateSplited[5]}`;
          let valuesSplited = values.slice(7, -1); // Prendre les valeurs du 7ème élément jusqu'à l'avant-dernier
          valuesSplited.unshift(formattedDate);
          let relevantValues = valuesSplited.map((value, i) => {
            if (i < 5 && i > 0) {
              return value === "1" ? "actif" : "inactif";
            }
            return value;
          });
          return {
            data: relevantValues,
          };
        });
        data = data.filter((log) => log !== null);
        data = data.reverse();
        setSeparatedData(data);
        setCircleLoading(false);
      } catch (err) {
        console.error(
          "Une erreur s'est produite lors de la récupération des données de l'API ancienne:",
          err
        );
        throw err;
      }
    };

    fetchData();
  }, [params.id]);

  const handleDownload = () => {
    setLoading(true);

    const csvData = separatedData.map((row) => ({
      DATE: row.data[0],
      "bobine A": row.data[1],
      "bobine B": row.data[2],
      "générateur A": row.data[3],
      "générateur B": row.data[4],
      Temp: row.data[5],
      "capteur 1": row.data[6],
      "capteur 2": row.data[7],
      "capteur 3": row.data[8],
      "capteur 4": row.data[9],
      "capteur 5": row.data[10],
      "capteur 6": row.data[11],
    }));

    const csvString = [
      headData,
      ...csvData.map((item) => [
        item.DATE,
        item["bobine A"],
        item["bobine B"],
        item["générateur A"],
        item["générateur B"],
        item.Temp,
        item["capteur 1"],
        item["capteur 2"],
        item["capteur 3"],
        item["capteur 4"],
        item["capteur 5"],
        item["capteur 6"],
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "export.csv");
    document.body.appendChild(link);
    link.click();

    setLoading(false);
  };

  const columns = headData.map((id, index) => ({
    Header: () => <div style={{ textAlign: "center" }}>{id}</div>,
    accessor: (d) => d.data[index],
    id: `${index}`,
  }));

  return (
    <>
      <Row className="CenterAll">
        <Col lg="12">
          <Card className="mt-5">
            <Col lg="12" className="d-flex justify-content-center">
              <Card>
                {circleLoading ? (
                  <div className="circle-placeholder">
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircleLoader color={"#3B90C8"} />
                    </div>
                  </div>
                ) : (
                  <CardBody>
                    {separatedData.length > 0 ? (
                      <ReactTable
                        data={separatedData}
                        columns={columns}
                        defaultPageSize={10}
                        previousText={localization.previousText}
                        nextText={localization.nextText}
                        pageText={localization.pageText}
                        ofText={localization.ofText}
                        rowsText={localization.rowsText}
                      />
                    ) : (
                      <div>Aucune donnée disponible</div>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Card>
          <Row className="export-btn-parent">
            <Button
              className="export-btn"
              color="primary"
              disabled={loading}
              onClick={handleDownload}
            >
              {loading ? "Téléchargement..." : "Exporter Les Données"}
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
}
