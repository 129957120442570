import React, { useState, useEffect, useMemo } from "react";
// import moment from 'moment';
import Datetime from "react-datetime";
import axios from "axios";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "../../../assets/css/custom.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Alert } from "reactstrap";
import Autocomplete from "react-google-autocomplete";
import ShowBoitierMap from "./showBoitierMap.js";
import BoitierLastAlert from "./boitierLastAlert.js";
import ShowBoitierAssign from "./showBoitierAssign.js";
import ShowBoitierComment from "./showBoitierComment.js";
import ShowBoitierImage from "./showBoitierImage.js";
import { API_URL, getApiConfig, getUserRoleId } from "../Config/apiConfig.js";

export default function ShowBoitierHeader() {
  const userRoleId = getUserRoleId();

  const nav = useNavigate();
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [formVisible, setFormVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  };

  const [dropdownOpenForVersion, setDropdownOpenForVersion] = useState(false);
  const toggleDropVersion = () =>
    setDropdownOpenForVersion((prevState) => !prevState);
  const [dropdownOpenForLeasing, setDropdownOpenForLeasing] = useState(false);
  const toggleDropLeasing = () =>
    setDropdownOpenForLeasing((prevState) => !prevState);
  const [dropdownOpenForGuarantee, setDropdownOpenForGuarantee] =
    useState(false);
  const toggleDropGuarantee = () =>
    setDropdownOpenForGuarantee((prevState) => !prevState);
  const [dropdownOpenForBoost, setDropdownOpenForBoost] = useState(false);
  const toggleDropBoost = () =>
    setDropdownOpenForBoost((prevState) => !prevState);
  const [dropdownOpenForState, setDropdownOpenForState] = useState(false);
  const toggleDropState = () =>
    setDropdownOpenForState((prevState) => !prevState);
  const [dropdownOpenForGenA, setDropdownOpenForGenA] = useState(false);
  const toggleDropGenA = () =>
    setDropdownOpenForGenA((prevState) => !prevState);
  const [dropdownOpenForGenB, setDropdownOpenForGenB] = useState(false);
  const toggleDropGenB = () =>
    setDropdownOpenForGenB((prevState) => !prevState);
  const [dropdownOpenForCoTime, setDropdownOpenForCoTime] = useState(false);
  const toggleDropCoTime = () =>
    setDropdownOpenForCoTime((prevState) => !prevState);

  const params = useParams();

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const [boitier_id, setBoitier_id] = useState("");
  const [comment, setComment] = useState("");
  const [markerLat, setMarkerLat] = useState("");
  const [markerLng, setMarkerLng] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [versionSoftware, setVersionSoftware] = useState("");
  const [modeBoost, setModeBoost] = useState("");
  const [connectionTimeLimit, setConnectionTimeLimit] = useState("");
  const [dateActivation, setDateActivation] = useState("");
  const [formattedDate, setFormattedDate] = useState(null);
  const [state, setState] = useState("");
  const [generatorStateA, setGeneratorStateA] = useState("");
  const [generatorStateB, setGeneratorStateB] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [user_id, setUser_id] = useState("");
  const [leasing, setLeasing] = useState("");
  const [dateLeasing, setDateLeasing] = useState("");
  const [guarantee, setGuarantee] = useState("");
  const [dateGuarantee, setDateGuarantee] = useState("");
  const [previousLSValue, setPreviousLSValue] = useState("");
  const [previousGRValue, setPreviousGRValue] = useState("");

  const [MB, setMB] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const [GNA, setGNA] = useState("");
  const [GNB, setGNB] = useState("");
  const [CTL, setCTL] = useState("");
  const [LS, setLS] = useState("");
  const [GR, setGR] = useState("");

  const connectionTimeLimitMap = useMemo(
    () => ({
      "sans délais": 1,
      "10 jours": 2,
      "30 jours": 3,
      "90 jours": 4,
      "180 jours": 5,
    }),
    []
  );

  const leasingMap = useMemo(
    () => ({
      "12 mois": 1,
      "24 mois": 2,
      "36 mois": 3,
      "48 mois": 4,
      "pas de contract": 5,
    }),
    []
  );

  const guaranteeMap = useMemo(
    () => ({
      "12 mois": 1,
      "24 mois": 2,
      "36 mois": 3,
      "48 mois": 4,
    }),
    []
  );
  useEffect(() => {
    setMB(modeBoost === "actif" ? 1 : 0);
    setSTATUS(state === "actif" ? 1 : 0);
    setGNA(generatorStateA === "actif" ? 1 : 0);
    setGNB(generatorStateB === "actif" ? 1 : 0);
    setCTL(
      connectionTimeLimitMap[connectionTimeLimit] ||
        connectionTimeLimitMap.default
    );
    setLS(leasingMap[leasing] || leasingMap.default);
    setGR(guaranteeMap[guarantee] || guaranteeMap.default);
    // eslint-disable-next-line
  }, [
    modeBoost,
    state,
    generatorStateA,
    generatorStateB,
    connectionTimeLimit,
    leasing,
    guarantee,
  ]);

  useEffect(() => {
    if (dateActivation && !isNaN(new Date(dateActivation))) {
      setFormattedDate(new Date(dateActivation).toLocaleDateString("en-GB"));
    }
  }, [dateActivation]);

  const fullAddress = `${address}, ${city}, ${postcode}, ${country}`;

  const handleSubmitFor = async (e) => {
    e.preventDefault();

    await geocodeAddress(fullAddress);

    let payload = {
      dateActivation: dateActivation,
      comment: comment,
      markerLat: markerLat,
      markerLng: markerLng,
      address: address,
      city: city,
      postcode: postcode,
      country: country,
      modeBoost: MB,
      state: STATUS,
      versionSoftware: versionSoftware,
      generatorStateA: GNA,
      generatorStateB: GNB,
      ConnectionTimeLimit: CTL,
    };

    if (LS !== previousLSValue) {
      payload.leasing = LS;
    }
    if (GR !== previousGRValue) {
      payload.guarantee = GR;
    }

    axios
      .post(API_URL + `updateBoitierInfo/${params.id}`, payload, getApiConfig())
      .then(function (response) {
        console.log("response", response);
        if (response.data === "success") {
          setAlertContent(
            <Alert color="success">Les données ont bien été enregistré !</Alert>
          );
          setAlert(true);
          const timeId = setTimeout(() => {
            setAlert(false);
          }, 3000);
        } else {
          setAlertContent(
            <Alert color="danger">
              Une erreur est survenue, veuillez réessayer
            </Alert>
          );
          setAlert(true);
          const timeId = setTimeout(() => {
            setAlert(false);
          }, 3000);
        }
      });
    setFormSubmitted(false);
    setFormVisible(false);
  };

  const location = useLocation();
  const isOld = location.state?.isOld;
  const idBoitier = location.state?.id;
  // console.log("isOld", isOld);

  //AXIOS GET REQUEST

  useEffect(() => {
    let showBoitierRequest = axios.get(
      API_URL + `showBoitier/${params.id}`,
      getApiConfig()
    );
    console.log("showBoitierRequest", showBoitierRequest);

    let showContractRequest = axios.get(
      API_URL + `showContract/${params.id}`,
      getApiConfig()
    );

    axios
      .all([showBoitierRequest, showContractRequest])
      .then(
        axios.spread(function (res1, res2) {
          setLeasing(res2.data?.leasing);
          setDateLeasing(res2.data?.dateLeasing);
          setGuarantee(res2.data?.guarantee);
          setPreviousLSValue(res2.data?.dateLeasing);
          setPreviousGRValue(res2.data?.guarantee);
          setDateGuarantee(res2.data?.dateGuarantee);
          setBoitier_id(res1.data?.id);
          setComment(res1.data?.comment);
          setAddress(res1.data?.address);
          setCity(res1.data?.city);
          setPostcode(res1.data?.postcode);

          setCountry(res1.data?.country);

          setVersionSoftware(res1.data?.versionSoftware);
          setModeBoost(res1.data?.modeBoost);
          console.log(res1.data?.modeBoost, "modeBoost");
          setConnectionTimeLimit(res1.data?.ConnectionTimeLimit);
          setDateActivation(res1.data?.dateActivation);
          setState(res1.data?.state);
          setGeneratorStateA(res1.data?.generatorStateA);
          setGeneratorStateB(res1.data?.generatorStateB);
          setCustomerName(
            res1.data?.user.name + " " + res1.data?.user.firstname
          );
          setUser_id(res1.data?.user_id);
          setMarkerLat(res1.data?.markerLat);
          setMarkerLng(res1.data?.markerLng);
          console.log("showBoitierRequest", res1);
        })
      )
      .catch((err) => {});
  }, [params.id]);

  const geocodeAddress = async (fullAddress) => {
    try {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: fullAddress }, (results, status) => {
        if (status === "OK") {
          const Ulocation = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          setMarkerLat(Ulocation.lat);
          setMarkerLng(Ulocation.lng);
        } else {
          console.error("Geocoding error:", status);
        }
      });
    } catch (error) {
      console.error("Geocoding error:", error);
    }
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10%",
          right: "10%",
          left: "20%",
          zIndex: "999",
        }}
      >
        {alert ? <div>{alertContent}</div> : <></>}
      </div>

      <Col className="noPad" lg="12">
        <Row className="CenterAll">
          <div className="containertitle">
            <h2 className="titlePage">INFORMATIONS GENERALES</h2>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className="CenterAll">
              <Col lg="12" className="mt-4 noPad">
                <Row>
                  <Col lg="5" md="6" className="noPad ">
                    {!formVisible || formSubmitted ? (
                      <div>
                        <Card className="cardContainer">
                          <CardBody>
                            <div className="containerTextCard noMarg">
                              <h4 className="titleCard ">
                                BOITIER N° {boitier_id}
                              </h4>
                              <div
                                style={{
                                  borderTop: "0.5px solid #fff",
                                  marginBottom: "20px",
                                }}
                              ></div>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Date d'activation
                                  </div>
                                  <div className="textRow">{formattedDate}</div>
                                </Row>
                              </Card>

                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Version</div>
                                  <div className="textRow">
                                    {versionSoftware}
                                  </div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Contrat leasing
                                  </div>
                                  <div className="textRow">{leasing}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Expiration leasing
                                  </div>
                                  <div className="textRow">{dateLeasing}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Contrat garantie
                                  </div>
                                  <div className="textRow">{guarantee}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Expiration garantie
                                  </div>
                                  <div className="textRow">{dateGuarantee}</div>
                                </Row>
                              </Card>

                              {userRoleId === "1" && (
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Mode boost</div>
                                    <div className="textRow">{modeBoost}</div>
                                  </Row>
                                </Card>
                              )}
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Etat du boitier
                                  </div>
                                  <div className="textRow">{state}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Etat générateur A
                                  </div>
                                  <div className="textRow">
                                    {generatorStateA}
                                  </div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Etat générateur B
                                  </div>
                                  <div className="textRow">
                                    {generatorStateB}
                                  </div>
                                </Row>
                              </Card>
                              {/* <Card className="rowContainer">
                                        <Row>
                                        <div className="titleRow" >
                                            Délai sans connexion
                                        </div>
                                        <div className="textRow" >{connectionTimeLimit}</div> 
                                        </Row>
                                </Card> */}
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">
                                    Emplacement du boitier
                                  </div>
                                  <div className="textRow">
                                    {address} , {postcode} {city} {country}
                                  </div>
                                </Row>
                              </Card>
                            </div>
                            {userRoleId != 5 && (
                              <div className="text-center">
                                <Button
                                  color="primary"
                                  onClick={handleToggleForm}
                                >
                                  Editer
                                </Button>
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      </div>
                    ) : (
                      <div>
                        <form onSubmit={handleSubmitFor}>
                          <Card className="cardContainer">
                            <CardBody>
                              <div className="containerTextCard">
                                <h4 className="titleCard">
                                  BOITIER N° {boitier_id}
                                </h4>
                                <div
                                  style={{
                                    borderTop: "0.5px solid #fff",
                                    marginBottom: "20px",
                                  }}
                                ></div>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Version</div>
                                    <div className="textRow">
                                      {/* <div className="btn btn-light" onClick={() => setShowDatePicker(true)}>
                                                    {moment(dateActivation).format('DD/MM/YYYY')}
                                                </div> */}

                                      {/* {showDatePicker && ( */}
                                      {/* // <div className="date-picker-overlay"> */}
                                      <Datetime
                                        value={formattedDate}
                                        onChange={(value) => {
                                          setDateActivation(
                                            value.format("YYYY-MM-DD")
                                          );
                                          setShowDatePicker(false);
                                        }}
                                        // inputProps={{ className: 'heighTextArea pl-3' }}
                                        className="dropdown-boitier-custom"
                                        dateFormat="DD/MM/YYYY"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onBlur={() => setShowDatePicker(false)}
                                      />
                                      {/* // </div> */}
                                      {/* )} */}
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Version</div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForVersion}
                                        toggle={toggleDropVersion}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {versionSoftware}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"4.1"}
                                            onClick={(e) =>
                                              setVersionSoftware(e.target.value)
                                            }
                                          >
                                            4.1
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"4.2"}
                                            onClick={(e) =>
                                              setVersionSoftware(e.target.value)
                                            }
                                          >
                                            4.2
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"4.3"}
                                            onClick={(e) =>
                                              setVersionSoftware(e.target.value)
                                            }
                                          >
                                            4.3
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"4.4"}
                                            onClick={(e) =>
                                              setVersionSoftware(e.target.value)
                                            }
                                          >
                                            4.4
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"4.5"}
                                            onClick={(e) =>
                                              setVersionSoftware(e.target.value)
                                            }
                                          >
                                            4.5
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Contract leasing
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForLeasing}
                                        toggle={toggleDropLeasing}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {leasing}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"12 mois"}
                                            onClick={(e) =>
                                              setLeasing(e.target.value)
                                            }
                                          >
                                            12 mois
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"24 mois"}
                                            onClick={(e) =>
                                              setLeasing(e.target.value)
                                            }
                                          >
                                            24 mois
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"36 mois"}
                                            onClick={(e) =>
                                              setLeasing(e.target.value)
                                            }
                                          >
                                            36 mois
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"48 mois"}
                                            onClick={(e) =>
                                              setLeasing(e.target.value)
                                            }
                                          >
                                            48 mois
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"pas de contract"}
                                            onClick={(e) =>
                                              setLeasing(e.target.value)
                                            }
                                          >
                                            pas de contract
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Date leasing</div>
                                    <div className="textRow">{dateLeasing}</div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Contrat garantie
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForGuarantee}
                                        toggle={toggleDropGuarantee}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {guarantee}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"12 mois"}
                                            onClick={(e) =>
                                              setGuarantee(e.target.value)
                                            }
                                          >
                                            12 mois
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"24 mois"}
                                            onClick={(e) =>
                                              setGuarantee(e.target.value)
                                            }
                                          >
                                            24 mois
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"36 mois"}
                                            onClick={(e) =>
                                              setGuarantee(e.target.value)
                                            }
                                          >
                                            36 mois
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"48 mois"}
                                            onClick={(e) =>
                                              setGuarantee(e.target.value)
                                            }
                                          >
                                            48 mois
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Date garantie
                                    </div>
                                    <div className="textRow">
                                      {dateGuarantee}
                                    </div>
                                  </Row>
                                </Card>
                                {userRoleId === "1" && (
                                  <Card className="rowContainer">
                                    <Row>
                                      <div className="titleRow">Mode boost</div>
                                      <div className="textRow">
                                        <Dropdown
                                          isOpen={dropdownOpenForBoost}
                                          toggle={toggleDropBoost}
                                        >
                                          <DropdownToggle className="dropdown-boitier-custom">
                                            {modeBoost}
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-custom">
                                            <DropdownItem
                                              style={{
                                                backgroundColor: "#9bc4de",
                                                color: "white",
                                              }}
                                              className="text-center"
                                              value={"actif"}
                                              onClick={(e) =>
                                                setModeBoost(e.target.value)
                                              }
                                            >
                                              actif
                                            </DropdownItem>
                                            <DropdownItem
                                              style={{
                                                backgroundColor: "#9bc4de",
                                                color: "white",
                                              }}
                                              className="text-center"
                                              value={"inactif"}
                                              onClick={(e) =>
                                                setModeBoost(e.target.value)
                                              }
                                            >
                                              inactif
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </div>
                                    </Row>
                                  </Card>
                                )}
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Etat du boitier
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForState}
                                        toggle={toggleDropState}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {state}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"actif"}
                                            onClick={(e) =>
                                              setState(e.target.value)
                                            }
                                          >
                                            actif
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"inactif"}
                                            onClick={(e) =>
                                              setState(e.target.value)
                                            }
                                          >
                                            inactif
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Etat générateur A
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForGenA}
                                        toggle={toggleDropGenA}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {generatorStateA}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"actif"}
                                            onClick={(e) =>
                                              setGeneratorStateA(e.target.value)
                                            }
                                          >
                                            actif
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"inactif"}
                                            onClick={(e) =>
                                              setGeneratorStateA(e.target.value)
                                            }
                                          >
                                            inactif
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Etat générateur B
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForGenB}
                                        toggle={toggleDropGenB}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {generatorStateB}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"actif"}
                                            onClick={(e) =>
                                              setGeneratorStateB(e.target.value)
                                            }
                                          >
                                            actif
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"inactif"}
                                            onClick={(e) =>
                                              setGeneratorStateB(e.target.value)
                                            }
                                          >
                                            inactif
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Délai sans connexion
                                    </div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForCoTime}
                                        toggle={toggleDropCoTime}
                                      >
                                        <DropdownToggle
                                          id="unbelievable-override-hover"
                                          className="dropdown-boitier-custom"
                                        >
                                          {connectionTimeLimit}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"sans délais"}
                                            onClick={(e) =>
                                              setConnectionTimeLimit(
                                                e.target.value
                                              )
                                            }
                                          >
                                            sans délais
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"10 jours"}
                                            onClick={(e) =>
                                              setConnectionTimeLimit(
                                                e.target.value
                                              )
                                            }
                                          >
                                            10 jours
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"30 jours"}
                                            onClick={(e) =>
                                              setConnectionTimeLimit(
                                                e.target.value
                                              )
                                            }
                                          >
                                            30 jours
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"90 jours"}
                                            onClick={(e) =>
                                              setConnectionTimeLimit(
                                                e.target.value
                                              )
                                            }
                                          >
                                            90 jours
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"180 jours"}
                                            onClick={(e) =>
                                              setConnectionTimeLimit(
                                                e.target.value
                                              )
                                            }
                                          >
                                            180 jours
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Emplacement du boitier
                                    </div>
                                    <div className="textRow">
                                      <Autocomplete
                                        apiKey={
                                          "AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk&libraries=places"
                                        }
                                        className="heighTextArea pl-3 form-control"
                                        id="custom-input-row"
                                        onPlaceSelected={(place) => {
                                          setAddress(
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] === "route"
                                            ).long_name +
                                              (place.address_components.find(
                                                (component) =>
                                                  component.types[0] ===
                                                  "street_number"
                                              )
                                                ? " " +
                                                  place.address_components.find(
                                                    (component) =>
                                                      component.types[0] ===
                                                      "street_number"
                                                  ).long_name
                                                : "")
                                          );
                                          const postalCodeComponent =
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] ===
                                                "postal_code"
                                            );

                                          if (postalCodeComponent) {
                                            setPostcode(
                                              postalCodeComponent.long_name
                                            );
                                          }
                                          setCity(
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] ===
                                                "locality"
                                            ).long_name
                                          );
                                          setCountry(
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] === "country"
                                            ).long_name
                                          );
                                        }}
                                        options={{
                                          types: ["address"],
                                          componentRestrictions: {
                                            country: "fr",
                                          },
                                        }}
                                      />
                                    </div>
                                  </Row>
                                </Card>
                              </div>
                              <div className="text-center">
                                <Button type="submit" color="primary">
                                  Enregistrer
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        </form>
                      </div>
                    )}
                    <ShowBoitierAssign
                      user_id={user_id}
                      customer_Name={customerName}
                    />
                  </Col>

                  <Col lg="7" md="6">
                    <Card>
                      {/* googleMap */}
                      <ShowBoitierMap
                        markerLat={markerLat}
                        markerLng={markerLng}
                      />
                      <ShowBoitierComment
                        comment={comment}
                        setComment={setComment}
                        handleSubmitFor={handleSubmitFor}
                      />
                      <ShowBoitierImage />
                      <BoitierLastAlert />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
