import React from "react";
import "../Sidebar/Sidebar.css";
import { useNavigate } from "react-router-dom";
import LogoMain from "assets/img/logoacquagestblanc-small.png";
import Box from "assets/png/box.png";
import ClassyUser from "assets/png/classy-user.png";
import Menu from "assets/png/menu.png";
import Product from "assets/png/product.png";
import Settings from "assets/png/settings.png";
import RoundUser from "assets/png/round-user.png";

export default function Distribbar() {
  const nav = useNavigate();
  const user_params_id = localStorage.getItem("user_params_id");
  const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

  const logout = () => {
    nav("/");
    localStorage.removeItem("token");
    localStorage.removeItem("user_params_id");
    localStorage.removeItem("loggedUser");
    localStorage.removeItem("user_role_id");
    localStorage.removeItem("Oldtoken");
  };

  function navigationDashboard() {
    nav("/Dashboard");
  }
  function navigationClient() {
    nav("/userList/5");
  }
  function navigationRevendeur() {
    nav("/userList/3");
  }
  function navigationTechnicien() {
    nav("/userList/4");
  }
  function navigationBoitier() {
    nav("/boitier");
  }

  return (
    <div className="sidebar-custom">
      <div className="sidebar_logo_container">
        <img className="sidebar_logo" src={LogoMain} alt="logo sidebar"></img>
      </div>
      <div className="sidebar-header-custom">
        {loggedUser ? (
          <h1>
            {loggedUser.name} {loggedUser.firstname}
          </h1>
        ) : null}
      </div>
      <div
        style={{ borderTop: "0.5px solid #fff", marginBottom: "20px" }}
      ></div>
      <div className="sidebar-items">
        <div className="item-custom" onClick={navigationDashboard}>
          <img className="sidebar-icon" src={Menu} alt="temp"></img>
          <span className="sidebar-text">DASHBOARD</span>
        </div>
        <div className="item-custom" onClick={navigationBoitier}>
          <img className="sidebar-icon" src={Box} alt="temp"></img>
          <span className="sidebar-text">BOITIERS</span>
        </div>
        <div className="item-custom" onClick={navigationRevendeur}>
          <img className="sidebar-icon" src={RoundUser} alt="temp"></img>
          <span className="sidebar-text">REVENDEURS</span>
        </div>
        <div className="item-custom" onClick={navigationTechnicien}>
          <img className="sidebar-icon" src={Product} alt="temp"></img>
          <span className="sidebar-text">TECHNICIENS</span>
        </div>
        <div className="item-custom" onClick={navigationClient}>
          <img className="sidebar-icon" src={RoundUser} alt="temp"></img>
          <span className="sidebar-text">CLIENTS</span>
        </div>
        <div className="logout-button-sidebar-custom">
          <button className="logoutButton" type="submit" onClick={logout}>
            Deconnexion
          </button>
        </div>
      </div>
    </div>
  );
}
