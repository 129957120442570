import React from "react";
import Chart from "react-apexcharts";

const RealtimeLineChart1 = (props) => {
  const options = {
    chart: {
      zoom: {
        enabled: true,
      },
      animations: {
        easing: "linear",
        dynamicAnimation: {
          speed: 500,
        },
      },
    },
    colors: ["#25E825", "#E8A425", "#9025E8"],

    tooltip: {
      x: {
        format: "yyyy/MM/dd HH:mm:ss.f",
      },
    },
    xaxis: {
      type: "datetime",
      range: props.range,
    },
    yaxis: {
      labels: {
        formatter: (val) => val.toFixed(0),
      },
      title: { text: "Value" },
    },
  };

  return <Chart type="line" options={options} series={props.dataList} />;
};

export default RealtimeLineChart1;
