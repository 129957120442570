import React from "react";
import "../../../assets/css/custom.css";

const DynamicText = ({
  capteurId,
  dataText,
  config,
  unite,
  isOld = false,
  date,
}) => {
  if (isOld) {
    return (
      <>
        <p>
          {" "}
          {config} : <span className="text-realtime-value">{dataText}</span>{" "}
          {unite}
          {`   ${date}`}
        </p>
      </>
    );
  } else {
    const sensor = config.find((sensor) => sensor.id === capteurId);

    if (sensor) {
      return (
        <>
          <p>
            {" "}
            {sensor.label} :{" "}
            <span className="text-realtime-value">
              {dataText[`capteur_${capteurId}_data`]}
            </span>{" "}
            {sensor.unite}
          </p>
        </>
      );
    }
    return null;
  }
};

export default DynamicText;
