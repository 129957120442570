import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-table-v6/react-table.css";
import ReactTable from "react-table-v6";
import { Card, CardBody, Row, Col } from "reactstrap";
import { API_URL, getApiConfig } from "../Config/apiConfig.js";
import { useNavigate } from "react-router-dom";
import {
  OLD_API_URL,
  oldGetApiConfig,
  oldToken,
} from "../Config/oldApiConfig.js";
import { token } from "../Config/apiConfig.js";

export default function UserListTab({ role_id }) {
  const nav = useNavigate();

  const [userListRequest, setUserListRequest] = useState("");
  const [oldUserListRequest, setOldUserListRequest] = useState("");
  const [userList, setUserList] = useState("");

  useEffect(() => {
    setUserListRequest(null);
    setOldUserListRequest(null);
    console.log("role_id", role_id);

    if (role_id == 2) {
      setUserListRequest("showConcessList");
      setOldUserListRequest("concessionnaire"); //aka : distributeur
    } else if (role_id == 3) {
      setUserListRequest("showResellerList");
      setOldUserListRequest("revendeur");
    } else if (role_id == 4) {
      setUserListRequest("showTechList");
      setOldUserListRequest("technicien");
    } else {
      setUserListRequest("showCustomerList");
      setOldUserListRequest("client");
    }
  }, [role_id]);

  useEffect(() => {
    const fetchData = async () => {
      let newApiRequests = [];
      let oldApiRequests = [];
      if (token) {
        console.log("userListRequest", userListRequest);
        newApiRequests = [axios.get(API_URL + userListRequest, getApiConfig())];
        console.log(newApiRequests);
      }
      if (oldToken) {
        oldApiRequests = [
          axios.get(OLD_API_URL + oldUserListRequest, oldGetApiConfig()),
        ];
      }

      try {
        if (newApiRequests.length > 0 && oldApiRequests.length > 0) {
          const [res1, res2] = await Promise.all([
            ...newApiRequests,
            ...oldApiRequests,
          ]);
          const combinedData = [...res1.data, ...res2.data.data];
          setUserList(combinedData);
        } else if (newApiRequests.length > 0) {
          const [res1] = await Promise.all(newApiRequests);
          setUserList(res1.data);
        } else if (oldApiRequests.length > 0) {
          const [res2] = await Promise.all(oldApiRequests);
          setUserList(res2.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (userListRequest && oldUserListRequest) {
      fetchData();
    }
  }, [userListRequest, oldUserListRequest]);

  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

  const formatAddress = (adresse) => {
    if (!adresse) return " ";
    return `${adresse.locality}, ${adresse.country}`;
  };

  const formatName = (d) => {
    if (d.name && d.firstname) {
      return `${d.name} ${d.firstname}`;
    } else if (d.contact && d.contact.nom && d.contact.prenom) {
      return `${d.contact.nom}, ${d.contact.prenom}`;
    }
    return " ";
  };

  function renderTelephoneCell(d) {
    return (
      <div>
        {d && d.phone1 && (
          <span>
            {d.phone1}
            <br />
          </span>
        )}
        {d && d.contact && d.contact.tel1 && (
          <span>
            {d.contact.tel1}
            <br />
          </span>
        )}
        {d && d.contact && d.contact.tel2 && (
          <span>
            {d.contact.tel2}
            <br />
          </span>
        )}
      </div>
    );
  }

  const formatRevendeur = (d) => {
    if (d.parentName || d.parentFirstname) {
      return `${d.parentName || ""} ${d.parentFirstname || ""}`.trim();
    } else {
      return d.revendeur?.nom ? d.revendeur?.nom : "";
    }
  };

  const getColumnsByRole = (role_id) => {
    switch (Number(role_id)) {
      case 2: // for concess
        return [
          {
            Header: () => <div style={{ textAlign: "center" }}>ID(TEST)</div>,
            accessor: (d) => d.id || d.id,
          },
          {
            Header: () => (
              <div style={{ textAlign: "center" }}>DISTRIBUTEUR</div>
            ),
            accessor: (d) => d.company || d.nom,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>CONTACT</div>,
            accessor: (d) => formatName(d),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>PAYS</div>,
            accessor: (d) => d.country || formatAddress(d.adresse),
          },
          {
            Header: () => (
              <div style={{ textAlign: "center" }}>NOMBRE DE REVENDEUR</div>
            ),
            accessor: (d) => d.count || d.nbrrevendeur || 0,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>STATUS</div>,
            accessor: (d) => d.state || d.statut,
            Cell: ({ value }) => (
              <div
                style={{
                  color: value === true || value === 1 ? "green" : "red",
                  textAlign: "center",
                }}
              >
                {value === true || value === 1 ? "actif" : "inactif"}
              </div>
            ),
          },
        ];
      case 3: // for Reseller
        return [
          {
            Header: () => <div style={{ textAlign: "center" }}>ID(TEST)</div>,
            accessor: (d) => d.id || d.id,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>REVENDEUR</div>,
            accessor: (d) => d.company || d.nom,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>CONTACT</div>,
            accessor: (d) => formatName(d),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>PAYS</div>,
            accessor: (d) => d.country || formatAddress(d.adresse),
          },
          {
            Header: () => (
              <div style={{ textAlign: "center" }}>NOMBRE DE CLIENT</div>
            ),
            accessor: (d) => d.count || d.nbreclient || 0,
          },
          {
            Header: () => (
              <div style={{ textAlign: "center" }}>NOMBRE DE BOITIER</div>
            ),
            accessor: (d) => d.nbrboitier || " ",
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>STATUS</div>,
            accessor: (d) => d.state || d.statut,
            Cell: ({ value }) => (
              <div
                style={{
                  color: value === true || value === 1 ? "green" : "red",
                  textAlign: "center",
                }}
              >
                {value === true || value === 1 ? "actif" : "inactif"}
              </div>
            ),
          },
        ];
      case 4: // for Tech
        return [
          {
            Header: () => <div style={{ textAlign: "center" }}>ID(TEST)</div>,
            accessor: (d) => d.id || d.id,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>TECHNICIEN</div>,
            accessor: (d) => formatName(d),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>TELEPHONE</div>,
            accessor: (d) => renderTelephoneCell(d),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>PAYS</div>,
            accessor: (d) => d.country || formatAddress(d.adresse),
          },
          {
            Header: () => (
              <div style={{ textAlign: "center" }}>
                NOMBRE D'INTERVENTIONS REALISES
              </div>
            ),
            accessor: (d) => "TODO",
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>STATUS</div>,
            accessor: (d) => d.state || d.statut,
            Cell: ({ value }) => (
              <div
                style={{
                  color: value === true || value === 1 ? "green" : "red",
                  textAlign: "center",
                }}
              >
                {value === true || value === 1 ? "actif" : "inactif"}
              </div>
            ),
          },
        ];
      default: // for Customer
        return [
          {
            Header: () => <div style={{ textAlign: "center" }}>ID(TEST)</div>,
            accessor: (d) => d.id || d.id,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>CLIENT</div>,
            accessor: (d) => d.company || d.nom,
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>CONTACT</div>,
            accessor: (d) => formatName(d),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>PAYS</div>,
            accessor: (d) => d.country || formatAddress(d.adresse),
          },
          {
            Header: () => <div style={{ textAlign: "center" }}>REVENDEUR</div>,
            accessor: (d) => formatRevendeur(d),
          },
        ];
    }
  };

  // Later in your code:
  const columns = getColumnsByRole(role_id).map((col, i) => {
    col.id = `${i}`;
    return col;
  });

  const onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: () => {
        console.log(rowInfo.original.id, "rowInfo");
        const roleIdNumber = Number(role_id);
        console.log(roleIdNumber, "role_id");
        switch (roleIdNumber) {
          case 2: // for concess
            nav(`/ShowDistrib/${rowInfo.original.id}`);
            break;
          case 3: // for Reseller
            nav(`/showResel/${rowInfo.original.id}`);
            break;
          case 4: // for Tech
            nav(`/showTech/${rowInfo.original.id}`);
            break;
          case 5: // for Customer
            nav(`/ShowUser/${rowInfo.original.id}`);
            break;
          default:
            return;
        }
      },
    };
  };

  return (
    <>
      <Row className="CenterAll">
        <Col lg="12">
          <Card className="mt-5">
            <Col lg="12" className="d-flex justify-content-center">
              <Card>
                <CardBody>
                  {userList ? (
                    <ReactTable
                      filterable
                      getTrProps={onRowClick}
                      value={userList?.id}
                      data={userList}
                      columns={columns}
                      defaultPageSize={10}
                      previousText={localization.previousText}
                      nextText={localization.nextText}
                      pageText={localization.pageText}
                      ofText={localization.ofText}
                      rowsText={localization.rowsText}
                      onPageChange={(pageIndex) => {
                        // A supprimer, ou modifier, ce code ne fonctionne pas mais est sensé demander de fetch de nouvelles données
                        const page = pageIndex + 1;
                        axios
                          .get("GOOD URL", {
                            params: {
                              nb_par_page: 10,
                              page: page,
                            },
                          })
                          .then((response) => {
                            // update your state with the new data
                          })
                          .catch((error) => {
                            console.error("There was an error!", error);
                          });
                      }}
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
}
