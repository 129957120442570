import react, { useState, useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import "../../../../assets/css/custom.css";
import Sidebar from "components/Sidebar/Sidebar.js";
import ShowTechnicienHeader from "./showTechnicienHeader.js";
import ShowTechnicienTab from "./showTechnicienTab.js";
import { useNavigate } from "react-router-dom";

export default function ShowTechnicien() {
  const nav = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      nav("/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="main-box">
        <div className="content-wrapper-custom">
          <ShowTechnicienHeader />
          <Col lg="12">
            <Card>
              <CardBody>
                <ShowTechnicienTab />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </>
  );
}
