import { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import RealtimeLineChart1 from "./RealtimeLineChart1";
import DynamicText from "./dynamicText.js";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL, getApiConfig } from "../Config/apiConfig.js";
import { OLD_API_URL, oldGetApiConfig } from "../Config/oldApiConfig";
import "../../../assets/css/custom.css";

const TIME_RANGE_IN_MILLISECONDS = 30 * 1000;
const ADDING_DATA_INTERVAL_IN_MILLISECONDS = 60000; // augmenter ?
export default function BoitierId1() {
  const nav = useNavigate();
  const isOld = true;

  const [killState, setKillState] = useState(true);
  const [lastAlert, setLastAlert] = useState();
  const [dateOfAlert, setDateOfAlert] = useState();
  const params = useParams();
  const formattedDate = (content) => {
    const date = new Date(content);
    const datePart = date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const timePart = date.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return `${datePart} ${timePart}`;
  };
  useEffect(() => {
    if (killState) {
      const fetchData = () => {
        axios
          .get(OLD_API_URL + `boitier/${params.id}`, oldGetApiConfig())
          .then((res) => {
            // Ici, vous pouvez ajouter votre expression régulière
            const regex = /^(\d{2}\/){6}(\d{1}\/){5}(\d{4}\/){7}AA$/;

            // Filtrer les éléments du journal qui correspondent à l'expression régulière
            const filteredJournal = res.data.data.journal.filter((entry) =>
              regex.test(entry.content)
            );

            const lastTemp =
              filteredJournal.length > 0
                ? filteredJournal[filteredJournal.length - 1]
                : null;
            // console.log            // console.log(lastTemp, "lastTemp");
            setDateOfAlert(
              lastTemp ? formattedDate(lastTemp.created_at) : null
            );

            if (lastTemp && lastTemp.content) {
              const contentParts = lastTemp.content.split("/");
              if (contentParts.length > 11) {
                setLastAlert(contentParts[11]);
              }
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setTimeout(fetchData, ADDING_DATA_INTERVAL_IN_MILLISECONDS);
          });
      };

      fetchData();

      return () => clearTimeout(fetchData);
    }
  }, [killState, params.id]);

  const pause = () => {
    setKillState(!killState);
  };

  const addParamToUrlForGraph = (event) => {
    nav("/boitierChart/" + params.id, { state: { isOld } });
  };

  return (
    <>
      <Row className="CenterAll">
        <div className="containertitle">
          <h2 className="titlePage">Dernière mesure</h2>
        </div>
      </Row>
      {lastAlert ? (
        <Card>
          <CardBody>
            <div className="wrapper-text-realtime">
              {lastAlert ? (
                <DynamicText
                  dataText={`${Math.round(
                    ((lastAlert * 3.3) / 4096 - 0.4) / 0.0195
                  )} `}
                  config={"Temperature interne"}
                  isOld={true}
                  unite={"°C"}
                  date={dateOfAlert}
                />
              ) : null}
            </div>
            {/* <div className="chartWidth ">
          <div className="d-flex justify-content-end"  >
                <Button className="btn-round btn-icon"  color="primary" onClick={pause}>
                  {killState ?
                  <i className="tim-icons icon-button-pause text-white"/> 
                  :
                  <i className="tim-icons icon-triangle-right-17 text-white"/>
                  }
                </Button>
              </div>
          { dataList ? <RealtimeLineChart1
                dataList={dataList}
                range={TIME_RANGE_IN_MILLISECONDS}
          /> : null }
          </div> */}
            <Button
              className="float-right"
              color="primary"
              onClick={addParamToUrlForGraph}
            >
              page graphique
            </Button>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardBody>
            <div className="wrapper-text-realtime">
              Aucune donnée en temps réel recu
            </div>
            <Button
              className="float-right"
              color="primary"
              onClick={addParamToUrlForGraph}
            >
              page graphique
            </Button>
          </CardBody>
        </Card>
      )}
    </>
  );
}
