import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useLocation } from "react-router-dom";

// auth
import Auth from "layouts/Auth/Auth.js";
import UpdatePassword from "layouts/Auth/UpdatePassword.js";
import ResetPassword from "layouts/Auth/ResetPassword.js";

//Dashboard
import Dashboard from "views/composant/Dashboard/Dashboard.js";

//BoitierList
import Boitiers from "views/composant/BoitierList/boitiers.js";

//UserManagement
import UserAdd from "views/composant/UserAdd/userAdd.js";
import UserList from "views/composant/UserList/userList.js";
import ShowClient from "views/composant/UserShow/clientShow/showClient.js";
import ShowTech from "views/composant/UserShow/technicienShow/showTechnicien.js";
import ShowResel from "views/composant/UserShow/revendeurShow/showRevendeur.js";
import ShowDistrib from "views/composant/UserShow/distributeurShow/showDistributeur.js";
//boitier
import ShowBoitier from "views/composant/BoitierShow/showBoitier.js";
import BoitierAlert from "views/composant/BoitierAlert/boitierAlert.js";
import BoitierChart from "views/composant/BoitierChart/boitierChart.js";
//old boitier
import OldBoitierAlert from "views/composant/BoitierAlert/oldBoitierAlert";

//reglages-admin
import Reglages from "views/composant/Admin/reglages/reglages.js";

// import UserProfile from "views/composant///.js";
import ClientReglages from "views/composant/Client/userSettings/ClientReglages.js";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";
import AdminNavbar from "components/Navbars/AdminNavbar";

// const nav = useNavigate();

// useEffect(() => {
//   if(!localStorage.getItem("token")) {
//     nav("/login");
//   }
// }, [])

//////////////////////////// les endroit ou on doit afficher la navbar admin ////////////////////////////
function AdminNavbarWrapper() {
  const location = useLocation();
  const isUpdatePasswordPage = location.pathname.includes("/UpdatePassword");
  const isResetPasswordPage = location.pathname.includes("/password/reset");

  const shouldRenderAdminNavbar =
    location.pathname !== "/" && !isUpdatePasswordPage && !isResetPasswordPage;
  return shouldRenderAdminNavbar ? (
    <AdminNavbar location={window.location} />
  ) : null;
}

const ShowUserFilter = () => {
  const { id } = useParams(); // récupère l'id de l'URL
  const outerString = localStorage.getItem("loggedUser"); // récupère l'objet user puis l'id propre a l'user (pas son role_id)
  const outerObject = JSON.parse(outerString);
  const userId = outerObject.id;
  if (id == userId) {
    return <ShowClient />;
  } else {
    return <Navigate to={`/`} />;
  }
};
function getUserRoleFromLocalStorage() {
  const userRoleId = localStorage.getItem("user_role_id");
  return userRoleId ? parseInt(userRoleId) : null;
}

function App() {
  const [userRole, setUserRole] = useState(
    parseInt(localStorage.getItem("user_role_id"))
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={
            <>
              <AdminNavbarWrapper />
              <Routes>
                {/* admin routes */}

                {userRole === 1 && (
                  <>
                    <Route path="/UserAdd/:id" element={<UserAdd />} />
                    <Route path="/UserList/:id" element={<UserList />} />
                    <Route path="/reglage" element={<Reglages />} />
                    <Route
                      path="/ClientReglages"
                      element={<ClientReglages />}
                    />
                    <Route path="/ShowUser/:id" element={<ShowClient />} />
                    <Route path="/ShowTech/:id" element={<ShowTech />} />
                    <Route path="/ShowResel/:id" element={<ShowResel />} />
                    <Route path="/ShowDistrib/:id" element={<ShowDistrib />} />

                    <Route path="/boitier" element={<Boitiers />} />
                    <Route path="/Dashboard" element={<Dashboard />} />

                    <Route path="/showBoitier/:id" element={<ShowBoitier />} />
                    <Route
                      path="/boitierAlert/:id"
                      element={<BoitierAlert />}
                    />
                    <Route
                      path="/OldBoitierAlert/:id"
                      element={<OldBoitierAlert />}
                    />
                    <Route
                      path="/boitierChart/:id"
                      element={<BoitierChart />}
                    />
                  </>
                )}
                {/* user routes */}
                {(userRole === 2 ||
                userRole === 3 ||
                userRole === 4 ||
                userRole === 5
                  ? true
                  : null) && (
                  <>
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/boitier" element={<Boitiers />} />
                    <Route path="/showBoitier/:id" element={<ShowBoitier />} />
                    <Route
                      path="/boitierAlert/:id"
                      element={<BoitierAlert />}
                    />
                    <Route
                      path="/OldBoitierAlert/:id"
                      element={<OldBoitierAlert />}
                    />
                    <Route
                      path="/boitierChart/:id"
                      element={<BoitierChart />}
                    />
                    <Route
                      path={`/UserList/${userRole}`}
                      element={<UserList />} // peut etre enlever le UserList/:id de client car ils n'en ont pas besoin
                    />
                    <Route
                      path={"/ShowUser/:id"}
                      element={<ShowUserFilter />}
                    />{" "}
                    {/* demander a fred si les concess revendeur peuvent voir la liste des clients ou pas dutout
                        s'il répond que non, alors on change showUser/:id par l'id */}{" "}
                  </>
                )}
                {/* global routes */}

                <Route path="/UpdatePassword" element={<UpdatePassword />} />
                <Route path="/password/reset" element={<ResetPassword />} />
                {/* lifting up de role_id quand on se connecte */}
                <Route path="/" element={<Auth setUserRole={setUserRole} />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
