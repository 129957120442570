import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import { Button } from "reactstrap";
import { CircleLoader } from "react-spinners";
import { OLD_API_URL, oldGetApiConfig } from "../Config/oldApiConfig";

export default function TabChart() {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState("6m");
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [sensors, setSensors] = useState([]);

  const params = useParams();

  // Objet de mappage pour renommer les labels des capteurs
  const sensorLabels = {
    value11: "Température interne",
    value12: "Capteur 1",
    value13: "Capteur 2",
    value14: "Capteur 3",
    // Ajoutez plus selon vos besoins
  };

  async function fetchData() {
    try {
      const response = await axios.get(
        OLD_API_URL + `boitier/showDataBoitier/${params.id}`,
        oldGetApiConfig()
      );
      const allChartData = response.data.data.capteurs;

      setSensors(Object.keys(allChartData));
      console.log(sensors, "sensorshe");
      if (!selectedSensor) {
        setSelectedSensor(Object.keys(allChartData)[0]);
      }

      const sensorData = allChartData[selectedSensor];
      const seriesData = {};
      Object.keys(sensorData[0]).forEach((key) => {
        seriesData[key] = sensorData.map((point) => ({
          x: new Date(point[key].x).getTime(),
          y: point[key].y,
        }));
      });

      setChartData(seriesData);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [params, timeRange, selectedSensor]);

  const chartOptions = {
    chart: {
      type: "area",
      height: 350,
    },
    xaxis: {
      type: "datetime",
      min: new Date().getTime() - getTimeRangeMillis(timeRange),
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        colorStops: [
          {
            offset: 0,
            color: "#00E396",
            opacity: 0.7,
          },
          {
            offset: 100,
            color: "#0092FF",
            opacity: 0.9,
          },
        ],
      },
    },
  };

  function getTimeRangeMillis(range) {
    switch (range) {
      case "1h":
        return 3600000;
      case "24h":
        return 86400000;
      case "1w":
        return 604800000;
      case "1m":
        return 2592000000;
      case "6m":
        return 15552000000;
      case "1y":
        return 31536000000;
      default:
        return 31536000000; // 1 year by default
    }
  }

  const setTimeFilter = (range) => {
    setTimeRange(range);
  };

  return (
    <div>
      <div className="btn-bar-chart">
        <Button onClick={() => setTimeFilter("1h")}>1 heure</Button>
        <Button onClick={() => setTimeFilter("24h")}>24 heures</Button>
        <Button onClick={() => setTimeFilter("1w")}>1 semaine</Button>
        <Button onClick={() => setTimeFilter("1m")}>1 mois</Button>
        <Button onClick={() => setTimeFilter("6m")}>6 mois</Button>
        <Button onClick={() => setTimeFilter("1y")}>1 an</Button>
      </div>
      <div className="btn-bar-sensor">
        {sensors.map((sensor) => (
          <Button key={sensor} onClick={() => setSelectedSensor(sensor)}>
            {sensor}
          </Button>
        ))}
      </div>
      {loading ? (
        <CircleLoader color={"#3B90C8"} />
      ) : (
        Object.keys(chartData).map((seriesKey) => (
          <div key={seriesKey} className="chart-container">
            <h3>{sensorLabels[seriesKey] || seriesKey}</h3>
            <Chart
              series={[
                {
                  name: sensorLabels[seriesKey] || seriesKey,
                  data: chartData[seriesKey],
                },
              ]}
              options={chartOptions}
              type="area"
              width="100%"
              height="350px"
            />
          </div>
        ))
      )}
    </div>
  );
}
