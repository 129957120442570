import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import "../../../../assets/css/custom.css";
// import Clientsactifs from '../../../../assets/svg/Clientsactifs.svg';
// import GoogleMaps from '../../maps/GoogleMaps';
// import { NavLink } from "react-router-dom";
import ShowRevendeurHeader from "./showRevendeurHeader.js";
import ShowRevendeurTab from "./showRevendeurTab.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { useNavigate } from "react-router-dom";
// import Map from '../../../../assets/images/map.jpg';

const ShowRevendeurs = (props) => {
  const history = useNavigate();
  const [modalLarge, setModalLarge] = React.useState(false);

  const toggleModalLarge = () => {
    setModalLarge(!modalLarge);
  };
  const handleClick = (id, country) => {
    history.push("/admin/ShowBoitier", { id: id, country: country });
  };
  return (
    <>
      <div className="main-box">
        {/* <Sidebar /> */}
        <div className="content-wrapper-custom">
          <ShowRevendeurHeader />
          <Col lg="12">
            {/* <Row className="CenterAll">
                    <div className="containertitle">
                        <h2 className="titlePage">INFORMATIONS GENERALES</h2>
                    </div>
                </Row> */}
            <Card>
              <CardBody>
                <ShowRevendeurTab />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </>
  );
};

export default ShowRevendeurs;
