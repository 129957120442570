import { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Row,
  Button,
} from "reactstrap";
import RealtimeLineChart1 from "./RealtimeLineChart1";
import DynamicText from "./dynamicText.js";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL, getApiConfig } from "../Config/apiConfig.js";
import "../../../assets/css/custom.css";

const TIME_RANGE_IN_MILLISECONDS = 30 * 1000;
const ADDING_DATA_INTERVAL_IN_MILLISECONDS = 1000;

export default function BoitierId1() {
  const nav = useNavigate();

  const defaultDataList = [
    {
      name: "capteur_1_data",
      data: [],
    },
    {
      name: "capteur_2_data",
      data: [],
    },
  ];
  const [dataList, setDataList] = useState(defaultDataList);
  const [killState, setKillState] = useState(true);
  const [dataText, setDataText] = useState(true);
  const [config, setConfig] = useState();
  const [dht11Keys, setDht11Keys] = useState([]);

  const params = useParams();

  useEffect(() => {
    axios
      .get(API_URL + `getConfig/${params.id}`, getApiConfig())
      .then((res) => {
        setConfig(res.data);
        const config = res.data;
        const dht11Ids = config
          .filter((c) => c.type === "Dht11")
          .map((c) => c.id);
        setDht11Keys(dht11Ids);
      })
      .catch((err) => {});
  }, [params.id]);

  useEffect(() => {
    if (killState) {
      const interval = setInterval(() => {
        axios
          .get(API_URL + `getInternData/${params.id}`, getApiConfig())
          .then((response) => {
            setDataText(response?.data);

            const newData = response.data;
            setDataList((prevDataList) => {
              return prevDataList.map((series) => {
                if (series.name === "capteur_1_data" && dht11Keys.includes(series.name)) {
                  return {
                    ...series,
                    data: [
                      ...series.data,
                      {
                        x: new Date(),
                        y: newData?.[`capteur_1_data`],
                      },
                    ],
                  };
                } else if (series.name === "capteur_2_data" && dht11Keys.includes(series.name)) {
                  return {
                    ...series,
                    data: [
                      ...series.data,
                      {
                        x: new Date(),
                        y: newData?.[`capteur_2_data`],
                      },
                    ],
                  };
                }
                return series;
              });
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }, ADDING_DATA_INTERVAL_IN_MILLISECONDS);

      return () => clearInterval(interval);
    }
  }, [killState, dht11Keys, params.id]);

  const pause = () => {
    setKillState(!killState);
  };

  const addParamToUrlForGraph = () => {
    nav("/boitierChart/" + params.id);
  };

  return (
    <>
      <Row className="CenterAll">
        <div className="containertitle">
          <h2 className="titlePage">CAPTEURS EN TEMPS REEL</h2>
        </div>
      </Row>
      {dataList && dataList.length > 0 ? (
        <Card>
          <CardBody>
            <div className="wrapper-text-realtime">
              {config && dht11Keys.length > 0 && (
                <>
                  <DynamicText
                    capteurId={dht11Keys[0]}
                    dataText={dataText}
                    config={config}
                  />
                  <DynamicText
                    capteurId={dht11Keys[1]}
                    dataText={dataText}
                    config={config}
                  />
                </>
              )}
            </div>
            <Button
              className="float-right"
              color="primary"
              onClick={addParamToUrlForGraph}
            >
              page graphique
            </Button>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardBody>
            <div className="wrapper-text-realtime">
              Aucune donnée en temps réel recu
            </div>
            <Button
              className="float-right"
              color="primary"
              onClick={addParamToUrlForGraph}
            >
              page graphique
            </Button>
            {config && dht11Keys.length > 0 && (
              <>
                <DynamicText
                  capteurId={dht11Keys[0]}
                  dataText={dataText}
                  config={config}
                />
                <DynamicText
                  capteurId={dht11Keys[1]}
                  dataText={dataText}
                  config={config}
                />
              </>
            )}
          </CardBody>
        </Card>
      )}
    </>
  );
}
