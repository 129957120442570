import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  Col,
} from "reactstrap";
import { CircleLoader } from "react-spinners";
import { OLD_API_URL, oldGetApiConfig } from "../Config/oldApiConfig";

export default function TabChart() {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState("6m");
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [capteurData, setCapteurData] = useState([]); // Ensure this is initialized as an array
  const [selectedFormule, setSelectedFormule] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [timeFilter, setTimeFilter] = useState("day");
  const [boitierData, setBoitierData] = useState([]);

  const params = useParams();

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  

  // Fetch sensor and capteur data
  async function fetchData() {
    try {
      const capteurImport = await axios.get(
        OLD_API_URL + `capteur`,
        oldGetApiConfig()
      );

      const response = await axios.get(
        OLD_API_URL + `boitier/${params.id}/mesure?q=${timeFilter}`,
        oldGetApiConfig()
      );

      const boitierImport = await axios.get(
        OLD_API_URL + `boitier/${params.id}/capteur`,
        oldGetApiConfig()
      );

      const allBoitierData = boitierImport.data;
      const allChartData = response.data.data;

      setBoitierData(Object.keys(allChartData));
      console.log(boitierImport.data, "boitierData");

      setSensors(Object.keys(allBoitierData));
      console.log(capteurImport.data.data, "sensors");

      setCapteurData(capteurImport.data.data); 
      console.log(Object.keys(allChartData), "capteurData");

      if (!selectedSensor) {
        setSelectedSensor(Object.keys(allChartData)[selectedSensor]);
              console.log(`Sensor changed: ${selectedSensor}`);

      }

      if (!selectedFormule && capteurImport.data.data.length > 0) {
        setSelectedFormule(capteurImport.data.data[0].formule);
      }

      const sensorData = allChartData[selectedSensor];
      console.log(sensorData, "sensorData, un tab de allcahrtdata avec selectedSensror en argu")
      const seriesData = {};
      // Object.keys(sensorData[0]).forEach((key) => {
      //   seriesData[key] = sensorData.map((point) => ({
      //     x: new Date(point[key].x).getTime(),
      //     y: applyFormula(point[key].y, selectedFormule),
      //   }));
      // });

      setChartData(seriesData);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  }

  // Apply the formula to the data points
  const applyFormula = (value, index, type) => {

    switch (index) 
    {
      case 0:
        return value;
      case 1:
        if (type === 1 && value != 0) 
        {
          let temp = ((value * 3,3/4096)-0,4)/0.01953;
          console.log("Temp(mv)", temp); 
          return temp;
        } 
        else if (type === 2 && value != 0) 
        {
          let temp = ((value *3,3/4096)-0,5)/0.01; 
          console.log("Temp(mA)", temp);
          return temp; 
        }
        else { return 0 };
      case 2:
        if (type === 1 && value != 0) 
        {
          let mV = 0.00002*(value*value)+1.2812*value+658.38;
          console.log("Tension(mV)", mV);
          return mV;
        } 
        else if (type === 2 && value != 0) 
        {
          let mA = 0.000005*(value*value)+0.0014*value+2.742;
          console.log("Tension(mA)", mA);
          return mA;
        }
        else { return 0 };

    }
  };

  // Fetch data when component mounts or when selectedSensor, timeRange, or selectedFormule changes
  useEffect(() => {
    fetchData();
  }, [params, timeRange, selectedSensor, selectedFormule]);

  const chartOptions = {
    chart: {
      type: "area",
      height: 350,
      width: "100%",
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
    },
    xaxis: {
      type: "datetime",
      min: new Date().getTime() - getTimeRangeMillis(timeRange),
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        colorStops: [
          {
            offset: 0,
            color: "#00E396",
            opacity: 0.7,
          },
          {
            offset: 100,
            color: "#0092FF",
            opacity: 0.9,
          },
        ],
      },
    },
  };

  // Convert time range to milliseconds
  function getTimeRangeMillis(range) {
    const ranges = {
      "1h": 3600000,
      "24h": 86400000,
      "1w": 604800000,
      "1m": 2592000000,
      "6m": 15552000000,
      "1y": 31536000000,
    };
    return ranges[range] || 31536000000; // 1 year by default
  }

  // const setTimeFilter = (range) => {
  //   setTimeRange(range);
  // };

  return (
    <Col>
      <div className="btn-bar-chart">
        <Button onClick={() => setTimeFilter("today")}>Aujourd'hui</Button>
        <Button onClick={() => setTimeFilter("day")}>1 Jour</Button>
        <Button onClick={() => setTimeFilter("week")}>1 Semaine</Button>
        <Button onClick={() => setTimeFilter("month")}>1 Mois</Button>
        {/* <Button onClick={() => setTimeFilter("6m")}>6 mois</Button> */}
        <Button onClick={() => setTimeFilter("year")}>1 an</Button>
      </div>
      <div className="btn-bar-sensor row justify-content-between ">
        {sensors.map((sensor) => (
          <Button key={sensor} onClick={() => setSelectedSensor(sensor)}>
            {sensor}
          </Button>
        ))}
      </div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret>
          {selectedFormule
            ? `Formule: ${selectedFormule}`
            : "Sélectionnez une formule"}
        </DropdownToggle>
        <DropdownMenu>
          {capteurData.length > 0 ? (
            capteurData.map((capteur, index) => (
              <DropdownItem
                key={index}
                onClick={() => setSelectedFormule(capteur.formule)}
              >
                {capteur.nom}: {capteur.formule}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem disabled>Aucune donnée disponible</DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      {loading ? (
        <CircleLoader color={"#3B90C8"} />
      ) : (
        Object.keys(chartData).map((seriesKey) => (
          <Card key={seriesKey} className="chart-container">
            <h3 className="chart-label">{seriesKey}</h3>
            <Chart
              series={[
                {
                  name: seriesKey,
                  data: chartData[seriesKey],
                },
              ]}
              options={chartOptions}
              type="area"
              width="100%"
              height="450px"
            />
          </Card>
        ))
      )}
    </Col>
  );
}
