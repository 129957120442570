import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "../../../src/assets/css/custom.css";

import ReactTable from "components/ReactTable/ReactTable.js";
import axios from "axios";
import {
  OLD_API_URL,
  oldGetApiConfig,
} from "views/composant/Config/oldApiConfig";
import React, { useState, useEffect } from "react";
const ReactTablesUsed = () => {
  const [data, setData] = useState([]);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newUnit, setNewUnit] = useState("");
  const [newColor, setNewColor] = useState("");
  const [newType, setNewType] = useState("");
  const [newFormule, setNewFormule] = useState("");
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [currentEditId, setCurrentEditId] = useState(null);
  const [currentDeleteId, setCurrentDeleteId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [formErrors, setFormErrors] = useState({
    newName: "",
    newDescription: "",
    newUnit: "",
    newColor: "",
    newType: "",
    newFormule: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        OLD_API_URL + `capteur`,
        oldGetApiConfig()
      );
      const fetchedData = response.data.data; // Extraction des données

      // Transforme les données reçues
      const transformedData = fetchedData.map((item) => ({
        id: item.id,
        name: item.nom,
        description: item.description,
        unit: item.unite,
        color: item.couleur,
        type: item.type,
        formule: item.formule,
        actions: renderActions(
          item.id,
          fetchedData.filter((d) => d.id === item.id)
        ),
      }));

      setData(transformedData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const renderActions = (key, data) => (
    <div className="actions-right d-flex flex-column">
      <Button
        onClick={() => openModalEdit(key, data)}
        color="primary"
        size="sm"
        className="mb-1"
      >
        Modifier
      </Button>
      <Button onClick={() => openModalDelete(key)} color="danger" size="sm">
        Supprimer
      </Button>
    </div>
  );

  const openModalAdd = () => {
    // Réinitialise les champs du formulaire
    setNewName("");
    setNewDescription("");
    setNewUnit("");
    setNewColor("");
    setNewType("");
    setNewFormule("");

    setModalAdd(true);
  };

  const closeModalAdd = () => {
    setModalAdd(false);
  };

  const openModalEdit = (id, data) => {
    const item = data.find((d) => d.id === id);

    if (item) {
      setCurrentEditId(id);
      setNewName(item.nom);
      setNewDescription(item.description);
      setNewUnit(item.unite);
      setNewColor(item.couleur);
      setNewType(item.type);
      setNewFormule(item.formule);
      setModalEdit(true);
    } else {
      console.error("Item not found in data");
    }
  };

  const closeModalEdit = () => {
    setModalEdit(false);
    setCurrentEditId(null);
  };

  const openModalDelete = (id) => {
    setCurrentDeleteId(id);
    setModalDelete(true);
  };

  const closeModalDelete = () => {
    setModalDelete(false);
    setCurrentDeleteId(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "newName":
        setNewName(value);
        break;
      case "newDescription":
        setNewDescription(value);
        break;
      case "newUnit":
        setNewUnit(value);
        break;
      case "newColor":
        setNewColor(value);
        break;
      case "newType":
        setNewType(value);
        break;
      case "newFormule":
        setNewFormule(value);
        break;
      default:
        break;
    }
  };

  // Fonction de validation de la formule
  const validateFormule = (formule) => {
    return formule.includes("x");
  };

  // Fonction de validation du type
  const validateType = (type) => {
    return /\d/.test(type);
  };

  // Fonction de validation des champs
  const validateFields = () => {
    let errors = {};

    if (!validateFormule(newFormule)) {
      errors.newFormule = "La formule doit contenir un 'x' minuscule.";
    }

    if (!validateType(newType)) {
      errors.newType = "Le type doit contenir au moins un chiffre.";
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    const newElement = {
      nom: newName,
      description: newDescription,
      unite: newUnit,
      couleur: newColor,
      type: newType,
      formule: newFormule,
    };

    try {
      setIsSubmitting(true);
      await axios.post(OLD_API_URL + "capteur", newElement, oldGetApiConfig());
      setAlertMessage("Ajout réussi!");
      setIsSubmitting(false);
      closeModalAdd();
      fetchData(); // Refetch data after adding
    } catch (error) {
      console.error("Error adding data", error);
      setAlertMessage("Erreur lors de l'ajout.");
      setIsSubmitting(false);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    const updatedElement = {
      nom: newName,
      description: newDescription,
      unite: newUnit,
      couleur: newColor,
      type: newType,
      formule: newFormule,
    };

    try {
      setIsSubmitting(true);
      await axios.put(
        OLD_API_URL + `capteur/${currentEditId}`,
        updatedElement,
        oldGetApiConfig()
      );
      setAlertMessage("Modification réussie!");
      setIsSubmitting(false);
      closeModalEdit();
      fetchData(); // Re-fetch data here
    } catch (error) {
      console.error("Error updating data", error);
      setAlertMessage("Erreur lors de la modification.");
      setIsSubmitting(false);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(
        OLD_API_URL + `capteur/${currentDeleteId}`,
        oldGetApiConfig()
      );
      setAlertMessage("Suppression réussie!");
      closeModalDelete();
      fetchData(); // Refetch data after deleting
    } catch (error) {
      console.error("Error deleting data", error);
      setAlertMessage("Erreur lors de la suppression.");
    }
  };

  return (
    <>
      <Row>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Capteurs</CardTitle>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={data}
              filterable
              columns={[
                { Header: "Nom", accessor: "name", width: 100 },
                { Header: "Description", accessor: "description", width: 150 },
                { Header: "Unité", accessor: "unit", width: 60 },
                { Header: "Couleur", accessor: "color", width: 80 },
                { Header: "Type", accessor: "type", width: 50 },
                { Header: "Formule", accessor: "formule", width: 100 },
                {
                  Header: "",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  width: 130,
                },
              ]}
              defaultPageSize={10}
              className="-striped -highlight"
              style={{ overflowX: "auto" }}
            />
          </CardBody>
        </Card>
      </Row>

      {/* Modal Ajouter */}
      <Modal isOpen={modalAdd} toggle={closeModalAdd}>
        <ModalHeader toggle={closeModalAdd}>Ajouter un Capteur</ModalHeader>
        <ModalBody>
          <Input
            type="text"
            name="newName"
            value={newName}
            onChange={handleChange}
            placeholder="Nom"
            invalid={formErrors.newName !== ""}
          />
          <Input
            type="text"
            name="newDescription"
            value={newDescription}
            onChange={handleChange}
            placeholder="Description"
            invalid={formErrors.newDescription !== ""}
          />
          <Input
            type="text"
            name="newUnit"
            value={newUnit}
            onChange={handleChange}
            placeholder="Unité"
            invalid={formErrors.newUnit !== ""}
          />
          <Input
            type="text"
            name="newColor"
            value={newColor}
            onChange={handleChange}
            placeholder="Couleur"
            invalid={formErrors.newColor !== ""}
          />
          <Input
            type="text"
            name="newType"
            value={newType}
            onChange={handleChange}
            placeholder="Type"
            invalid={formErrors.newType !== ""}
          />
          <Input
            type="text"
            name="newFormule"
            value={newFormule}
            onChange={handleChange}
            placeholder="Formule"
            invalid={formErrors.newFormule !== ""}
          />
          {Object.keys(formErrors).map(
            (key) =>
              formErrors[key] && (
                <div key={key} className="error">
                  {formErrors[key]}
                </div>
              )
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModalAdd}>
            Annuler
          </Button>
          <Button color="primary" onClick={handleAdd} disabled={isSubmitting}>
            {isSubmitting ? "En cours..." : "Ajouter"}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal Modifier */}
      <Modal isOpen={modalEdit} toggle={closeModalEdit}>
        <ModalHeader toggle={closeModalEdit}>Modifier un Capteur</ModalHeader>
        <ModalBody>
          <Input
            type="text"
            name="newName"
            value={newName}
            onChange={handleChange}
            placeholder="Nom"
            invalid={formErrors.newName !== ""}
          />
          <Input
            type="text"
            name="newDescription"
            value={newDescription}
            onChange={handleChange}
            placeholder="Description"
            invalid={formErrors.newDescription !== ""}
          />
          <Input
            type="text"
            name="newUnit"
            value={newUnit}
            onChange={handleChange}
            placeholder="Unité"
            invalid={formErrors.newUnit !== ""}
          />
          <Input
            type="text"
            name="newColor"
            value={newColor}
            onChange={handleChange}
            placeholder="Couleur"
            invalid={formErrors.newColor !== ""}
          />
          <Input
            type="text"
            name="newType"
            value={newType}
            onChange={handleChange}
            placeholder="Type"
            invalid={formErrors.newType !== ""}
          />
          <Input
            type="text"
            name="newFormule"
            value={newFormule}
            onChange={handleChange}
            placeholder="Formule"
            invalid={formErrors.newFormule !== ""}
          />
          {Object.keys(formErrors).map(
            (key) =>
              formErrors[key] && (
                <div key={key} className="error">
                  {formErrors[key]}
                </div>
              )
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModalEdit}>
            Annuler
          </Button>
          <Button
            color="primary"
            onClick={handleEditSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "En cours..." : "Modifier"}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal Supprimer */}
      <Modal isOpen={modalDelete} toggle={closeModalDelete}>
        <ModalHeader toggle={closeModalDelete}>
          Confirmer la suppression
        </ModalHeader>
        <ModalBody>Êtes-vous sûr de vouloir supprimer cet élément ?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModalDelete}>
            Annuler
          </Button>
          <Button color="danger" onClick={handleDeleteConfirm}>
            Supprimer
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReactTablesUsed;
