import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import "../../../assets/css/custom.css";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";
import TabAlert from "./tabAlert.js";

export default function BoitierAlert() {
  const nav = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      nav("/login");
    }
  }, [nav]);

  const params = useParams();

  const previousPage = () => {
    nav("/showBoitier/" + params.id);
  };

  return (
    <>
      <div className="main-box">
        {/* <Sidebar /> */}
        <div className="content-wrapper-custom">
          <Button
            className="float-right"
            color="primary"
            onClick={previousPage}
            style={{ zIndex: "1" }}
          >
            Retour à la page précédente
          </Button>
          <Col lg="12">
            <Row className="CenterAll">
              <div className="containertitle">
                <h2 className="titlePage">ALERTES DE VOTRE BOITIER</h2>
              </div>
            </Row>
            <Card>
              <CardBody>
                <TabAlert />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </>
  );
}
