import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactTable from "react-table-v6";
import { useParams } from "react-router-dom";
import "../../../assets/css/custom.css";
import { Button, Card, Row, CardBody } from "reactstrap";
import { OLD_API_URL, oldGetApiConfig } from "../Config/oldApiConfig";
import { getUserRoleId } from "../Config/apiConfig";

const localization = {
  previousText: "Précédent",
  nextText: "Suivant",
  pageText: "Page",
  ofText: "sur",
  rowsText: "lignes",
};

const roleId = getUserRoleId();

export default function OldTabAlert() {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [boitierAlert, setBoitierAlert] = useState();

  // Function to format date
  const formattedDate = (content) => {
    const date = new Date(content);
    const datePart = date.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const timePart = date.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return `${datePart} ${timePart}`;
  };

  // Function to determine if an alert should be displayed
  const shouldDisplayAlert = (alert) => {
    // Ne pas afficher les alertes "Données entrantes", insensible à la casse
    if (alert.status.content.toLowerCase() === "données entrantes") {
      return false;
    }
  
    const alertsVisibility = {
      "Perte de connexion": [1, 2, 3, 4, 5],
      "Retour de connexion": [1, 2, 3, 4, 5],
      "Nouveau boîtier a été créé": [1, 2, 3, 4, 5],
      "Redémarrage du boîtier": [1, 2, 3, 4, 5],
      "Intervention réalisée": [1, 2, 3, 4, 5],
      "Changement d’information dans la base (position, client)": [1, 2, 3, 4],
      "Température trop haute": [1, 2, 3, 4, 5],
    };
  
    return alertsVisibility[alert.status.content]?.includes(parseInt(roleId)) ?? true;
  };
  

  useEffect(() => {
    axios.get(OLD_API_URL + `boitier/${params.id}`, oldGetApiConfig())
      .then((res) => {
        const filteredAlerts = res.data.data.journal
          .filter(alert => shouldDisplayAlert(alert))
          .reverse(); // Assurez-vous que le filtre est bien appliqué ici
        setBoitierAlert(filteredAlerts);
        console.log(filteredAlerts);
      })
      .catch(console.error);
  }, [params.id]);
  

 // Function to handle CSV data download
const handleDownload = () => {
  setLoading(true);
  axios
    .get(OLD_API_URL + `boitier/${params.id}`, oldGetApiConfig())
    .then((res) => {
      // Filtrer les données avant de les convertir en CSV
      const filteredData = res.data.data.journal.filter(shouldDisplayAlert);
      const csvData = convertDataToCSV(filteredData);
      const url = window.URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      setLoading(false);
    });
};

// Convert data to CSV format
function convertDataToCSV(data) {
  const csvRows = ['Alerte,Date'];
  data.forEach((item) => {
    const date = item.created_at ? formattedDate(item.created_at) : '';
    csvRows.push(`${item.status.content},${date}`);
  });
  return csvRows.join('\n');
}


  const columns = [
    {
      Header: () => <div style={{ textAlign: "center" }}>ALERTE</div>,
      accessor: (d) => <div>{d.status.content}</div>,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>DATE</div>,
      accessor: (d) => d.created_at ? formattedDate(d.created_at) : "",
    },
  ].map((col, i) => ({ ...col, id: `${i}` }));

  return (
    <>
      <Row className="CenterAll">
        <Card className="mt-5">
          <CardBody>
            {boitierAlert && (
              <ReactTable
                data={boitierAlert}
                columns={columns}
                defaultPageSize={10}
                previousText={localization.previousText}
                nextText={localization.nextText}
                pageText={localization.pageText}
                ofText={localization.ofText}
                rowsText={localization.rowsText}
              />
            )}
          </CardBody>
        </Card>
        <Button
          className="float-right"
          color="primary"
          disabled={loading}
          onClick={handleDownload}
        >
          Exporter Les Données
        </Button>
      </Row>
    </>
  );
}
