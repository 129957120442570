const OLD_API_URL = "https://api-acquagest.shenron.dev/api/";
const oldToken = localStorage.getItem("Oldtoken");
function oldGetApiConfig() {
  

  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + oldToken,
    },
  };
}

function getOldMultipartConfig() {
  const oldToken = localStorage.getItem("Oldtoken");

  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + oldToken,
    },
  };
}

function oldGetUserRoleId() {
  return localStorage.getItem("user_role_id");
}
function oldGetUserId() {
  return localStorage.getItem("user_params_id");
}

export {
  oldToken,
  OLD_API_URL,
  oldGetApiConfig,
  getOldMultipartConfig,
  oldGetUserRoleId,
  oldGetUserId,
};
