import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Map from "../../Map/map.js";
import "../../../../assets/css/custom.css";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { CircleLoader } from "react-spinners";
import Autocomplete from "react-google-autocomplete";
import { API_URL, getApiConfig } from "views/composant/Config/apiConfig.js";

export default function ShowdistributeurHeader() {
  const nav = useNavigate();

  const [formVisible, setFormVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  };

  const [apiKey, setApiKey] = useState(
    "AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk"
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const [modalComment, setModalComment] = React.useState(false);
  const toggleModalComment = () => {
    setModalComment(!modalComment);
  };

  const [dropdownOpenForState, setDropdownOpenForState] = useState(false);
  const toggleDropState = () =>
    setDropdownOpenForState((prevState) => !prevState);

  //TOKEN
  const token = localStorage.getItem("token");

  const params = useParams();

  //ALERT
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  //DATA
  const [comment, setComment] = useState("");
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [siret, setSiret] = useState("");
  const [tva, setTVA] = useState("");
  const [state, setState] = useState("");
  const [markerLat, setMarkerLat] = useState("");
  const [markerLng, setMarkerLng] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    setStatus(state === "actif" ? 1 : 0);
  }, [state]);

  const handleSubmitFor = async (e) => {
    e.preventDefault();

    let payload = {
      comment: comment,
      company: company,
      name: name,
      firstname: firstname,
      markerLat: markerLat,
      markerLng: markerLng,
      address: address,
      city: city,
      postcode: postcode,
      country: country,
      phone1: phone1,
      phone2: phone2,
      email: email,
      website: website,
      siret: siret,
      tva: tva,
      state: status,
    };

    axios
      .post(API_URL + `updateUserInfo/${params.id}`, payload, getApiConfig())
      .then(function (response) {
        if (response.data === "success") {
          setAlertContent(
            <Alert color="success alert-big-screen-m-l">
              Les données ont bien été enregistré !
            </Alert>
          );
          setAlert(true);
          const timeId = setTimeout(() => {
            setAlert(false);
          }, 3000);
        } else {
          setAlertContent(
            <Alert color="danger alert-big-screen-m-l">
              Une erreur est survenue, veuillez réessayer
            </Alert>
          );
          setAlert(true);
          const timeId = setTimeout(() => {
            setAlert(false);
          }, 3000);
        }
      })
      .catch((error) => {
        alert(error);
      });
    setFormSubmitted(false);
    setFormVisible(false);
  };

  useEffect(() => {
    axios
      .get(API_URL + `showUser/${params.id}`, getApiConfig())
      .then((res) => {
        setComment(res.data?.user.comment || "");
        setCompany(res.data?.user.company || "");
        setName(res.data?.user.name.toUpperCase() || "");
        setFirstname(res.data?.user.firstname.toUpperCase() || "");
        setPhone1(res.data?.user.phone1 || "");
        setPhone2(res.data?.user.phone2 || "");
        setEmail(res.data?.user.email || "");
        setWebsite(res.data?.user.website || "");
        setSiret(res.data?.user.siret || "");
        setTVA(res.data?.user.tva || "");
        setState(res.data?.user.state || "");
        setMarkerLat(res.data?.user.markerLat || ""); // Utilisez une chaîne vide si la valeur est null
        setMarkerLng(res.data?.user.markerLng || ""); // Utilisez une chaîne vide si la valeur est null
        setAddress(res.data?.user.address || ""); // Utilisez une chaîne vide si la valeur est null
        setCity(res.data?.user.city || ""); // Utilisez une chaîne vide si la valeur est null
        setPostcode(res.data?.user.postCode || ""); // Utilisez une chaîne vide si la valeur est null
      })
      .catch((err) => {});
    // eslint-disable-next-line
  }, []);

  const fullAddress = `${address}, ${city}, ${postcode}, ${country}`;

  useEffect(() => {
    const geocoder = new window.google.maps.Geocoder({
      apiKey: apiKey,
    });
    geocoder.geocode({ address: fullAddress }, (results, status) => {
      if (status === "OK") {
        const Ulocation = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        setMarkerLat(Ulocation.lat);
        setMarkerLng(Ulocation.lng);
      }
    });
    // eslint-disable-next-line
  }, [fullAddress]);

  const location = { lat: markerLat, lng: markerLng };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10%",
          right: "10%",
          left: "20%",
          zIndex: "999",
        }}
      >
        {alert ? <div>{alertContent}</div> : <></>}
      </div>
      <Col lg="12">
        <Row className="CenterAll">
          <div className="containertitle">
            <h2 className="titlePage">INFORMATIONS DISTRIBUTEUR</h2>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className="CenterAll">
              <Col lg="12" className="mt-4">
                <Row>
                  <Col lg="5" md="6">
                    {!formVisible || formSubmitted ? (
                      <div>
                        <Card className="cardContainer">
                          <CardBody>
                            <div className="containerTextCard">
                              <h4 className="titleCard ">
                                INFORMATIONS GENERALES
                              </h4>
                              <div
                                style={{
                                  borderTop: "0.5px solid #fff",
                                  marginBottom: "20px",
                                }}
                              ></div>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Entreprise</div>
                                  <div className="textRow">{company}</div>
                                </Row>
                              </Card>

                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Contact</div>
                                  <div className="textRow">
                                    {firstname} {name}
                                  </div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Téléphone 1</div>
                                  <div className="textRow">{phone1}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Téléphone 2</div>
                                  <div className="textRow">{phone2}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">E-mail</div>
                                  <div className="textRow">{email}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Site Internet</div>
                                  <div className="textRow">{website}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">SIRET</div>
                                  <div className="textRow">{siret}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">TVA</div>
                                  <div className="textRow">{tva}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Status</div>
                                  <div className="textRow">{state}</div>
                                </Row>
                              </Card>
                              <Card className="rowContainer">
                                <Row>
                                  <div className="titleRow">Adresse</div>
                                  <div className="textRow">
                                    {address} , {postcode} {city} {country}
                                  </div>
                                </Row>
                              </Card>
                            </div>
                            <div className="text-center">
                              <Button
                                color="primary"
                                onClick={handleToggleForm}
                              >
                                Editer
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    ) : (
                      <div>
                        <form onSubmit={handleSubmitFor}>
                          <Card className="cardContainer">
                            <CardBody>
                              <div className="containerTextCard">
                                <h4 className="titleCard ">
                                  EDITER LES INFORMATIONS UTILISATEURS
                                </h4>
                                <div
                                  style={{
                                    borderTop: "0.5px solid #fff",
                                    marginBottom: "20px",
                                  }}
                                ></div>
                                <Card className="rowContainer">
                                  <div className="input-container-row-custom">
                                    <div className="titleRow">Entreprise</div>
                                    <div className="textRow">
                                      <Input
                                        value={company}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setCompany(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Card>

                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Contact</div>
                                    <div className="textRow">
                                      <Input
                                        value={firstname}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row-contact"
                                        onChange={(e) =>
                                          setFirstname(e.target.value)
                                        }
                                      />
                                      <Input
                                        value={name}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row-contact"
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Téléphone 1</div>
                                    <div className="textRow">
                                      <Input
                                        value={phone1}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setPhone1(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Téléphone 2</div>
                                    <div className="textRow">
                                      <Input
                                        value={phone2}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setPhone2(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">E-mail</div>
                                    <div className="textRow">
                                      <Input
                                        value={email}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">
                                      Site Internet
                                    </div>
                                    <div className="textRow">
                                      <Input
                                        value={website}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setWebsite(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">SIRET</div>
                                    <div className="textRow">
                                      <Input
                                        value={siret}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) =>
                                          setSiret(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">TVA</div>
                                    <div className="textRow">
                                      <Input
                                        value={tva}
                                        className="heighTextArea pl-3"
                                        type="text"
                                        name="text"
                                        id="custom-input-row"
                                        onChange={(e) => setTVA(e.target.value)}
                                      />
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Status</div>
                                    <div className="textRow">
                                      <Dropdown
                                        isOpen={dropdownOpenForState}
                                        toggle={toggleDropState}
                                      >
                                        <DropdownToggle className="dropdown-boitier-custom">
                                          {state}
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-custom">
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"actif"}
                                            onClick={(e) =>
                                              setState(e.target.value)
                                            }
                                          >
                                            actif
                                          </DropdownItem>
                                          <DropdownItem
                                            style={{
                                              backgroundColor: "#9bc4de",
                                              color: "white",
                                            }}
                                            className="text-center"
                                            value={"inactif"}
                                            onClick={(e) =>
                                              setState(e.target.value)
                                            }
                                          >
                                            inactif
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                  </Row>
                                </Card>
                                <Card className="rowContainer">
                                  <Row>
                                    <div className="titleRow">Adresse</div>
                                    <div className="textRow">
                                      <Autocomplete
                                        apiKey={
                                          "AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk&libraries=places"
                                        }
                                        className="heighTextArea pl-3 form-control"
                                        id="custom-input-row"
                                        onPlaceSelected={(place) => {
                                          setAddress(
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] === "route"
                                            ).long_name +
                                              (place.address_components.find(
                                                (component) =>
                                                  component.types[0] ===
                                                  "street_number"
                                              )
                                                ? " " +
                                                  place.address_components.find(
                                                    (component) =>
                                                      component.types[0] ===
                                                      "street_number"
                                                  ).long_name
                                                : "")
                                          );
                                          const postalCodeComponent =
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] ===
                                                "postal_code"
                                            );

                                          if (postalCodeComponent) {
                                            setPostcode(
                                              postalCodeComponent.long_name
                                            );
                                          }
                                          setCity(
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] ===
                                                "locality"
                                            ).long_name
                                          );
                                          setCountry(
                                            place.address_components.find(
                                              (component) =>
                                                component.types[0] === "country"
                                            ).long_name
                                          );
                                        }}
                                        options={{
                                          types: ["address"],
                                          componentRestrictions: {
                                            country: "fr",
                                          },
                                        }}
                                      />
                                    </div>
                                  </Row>
                                </Card>
                              </div>
                              <div className="text-center">
                                <Button type="submit" color="primary">
                                  Enregistrer
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        </form>
                      </div>
                    )}
                  </Col>
                  <Col lg="7" md="6">
                    <Card>
                      {/* <GoogleMaps /> */}
                      <CardBody className="mapContainer rightContainer">
                        <div
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            height: "400px",
                          }}
                        >
                          {loading ? (
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircleLoader color={"#3B90C8"} />
                            </div>
                          ) : (
                            <Map
                              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk&libraries=places`}
                              loadingElement={
                                <div
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <CircleLoader color={"red"} />
                                </div>
                              }
                              containerElement={
                                <div style={{ height: `100%` }} />
                              }
                              mapElement={<div style={{ height: `100%` }} />}
                              location={location}
                            />
                          )}
                        </div>
                      </CardBody>
                      <div className="text-center">
                        <CardBody className="rightContainer">
                          <Row className="d-flex justify-content-between mr-4 ml-3">
                            <h4 className="titleCardContrast">
                              Informations complémentaires
                            </h4>
                            <div
                              style={{
                                borderTop: "0.5px solid #fff",
                                marginBottom: "20px",
                              }}
                            ></div>
                            <Button
                              className="btn-round btn-icon "
                              color="primary"
                              onClick={toggleModalComment}
                            >
                              <i className="tim-icons icon-simple-add text-white" />
                            </Button>
                          </Row>
                          <div className="mt-4 pb-3 mr-4 ml-4"> {comment}</div>
                        </CardBody>
                      </div>
                      {/* MODAL COMMENT */}
                      <Modal
                        isOpen={modalComment}
                        toggle={toggleModalComment}
                        size="lg"
                      >
                        <ModalHeader
                          className="justify-content-center"
                          toggle={toggleModalComment}
                        >
                          <div style={{ fontSize: "1.0625rem" }}>
                            Ajouter des informations complémentaires
                          </div>
                        </ModalHeader>
                        <ModalBody>
                          <form onSubmit={handleSubmitFor}>
                            <Input
                              value={comment}
                              className="heighTextArea pl-3"
                              type="textarea"
                              cols="50"
                              rows="15"
                              name="text"
                              id="exampleText"
                              placeholder="Informations complémentaires..."
                              onChange={(e) => setComment(e.target.value)}
                            />
                            <Button
                              className="float-right mt-3 mr-5"
                              type="submit"
                              color="primary"
                              onClick={toggleModalComment}
                            >
                              Ajouter
                            </Button>
                          </form>
                        </ModalBody>
                      </Modal>
                      {/* END MODAL COMMENT */}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}
