import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactTable from "react-table-v6";
import { useParams } from "react-router-dom";
import "../../../assets/css/custom.css";
import { Button, Card, Row, CardBody } from "reactstrap";
import { getApiConfig, API_URL } from "../Config/apiConfig";

const localization = {
  previousText: "Précédent",
  nextText: "Suivant",
  pageText: "Page",
  ofText: "sur",
  rowsText: "lignes",
};

export default function TabAlert() {
  const params = useParams();

  // Download State
  const [loading, setLoading] = useState(false);

  // Data
  const [boitierAlert, setBoitierAlert] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}everyAlertByBoitier/${params.id}`, getApiConfig())
      .then((res) => {
        setBoitierAlert(res.data);
        console.log(res, "boitierAlert");
      })
      .catch((err) => {
        console.error(err);
      });
  }, [params.id]);

  const handleDownload = () => {
    axios
      .get(`${API_URL}exportAlertLog/${params.id}`, {
        ...getApiConfig(),
        responseType: 'blob', 
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const columns = [
    {
      Header: () => <div style={{ textAlign: "center" }}>ALERTE</div>,
      accessor: (d) => {
        return d.unite === "Booléen" ? (
          <div>{d.title}</div>
        ) : (
          <div>
            {d.title}: {d.value} {d.unite}
          </div>
        );
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>DATE</div>,
      accessor: (d) => d.datetime,
    },
  ].map((col, i) => {
    col.id = `${i}`;
    return col;
  });

  return (
    <>
      <Row className="CenterAll">
        <Card className="mt-5">
          <Card>
            <CardBody>
              {boitierAlert.length > 0 ? (
                <ReactTable
                  data={boitierAlert}
                  columns={columns}
                  defaultPageSize={10}
                  previousText={localization.previousText}
                  nextText={localization.nextText}
                  pageText={localization.pageText}
                  ofText={localization.ofText}
                  rowsText={localization.rowsText}
                />
              ) : (
                <div>Aucune donnée disponible</div>
              )}
            </CardBody>
          </Card>
        </Card>
        <Button
          className="float-right"
          color="primary"
          disabled={loading}
          onClick={handleDownload}
        >
          {loading ? 'Téléchargement...' : 'Exporter Les Données'}
        </Button>
      </Row>
    </>
  );
}