import React, { useState, useEffect } from "react";
import { CardBody } from "reactstrap";
import "../../../assets/css/custom.css";
import Map from "../Map/map.js";
import { CircleLoader } from "react-spinners";

export default function ShowBoitierMap({ markerLat, markerLng }) {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState({
    lat: typeof markerLat === "number" ? markerLat : undefined,
    lng: typeof markerLng === "number" ? markerLng : undefined,
  });

  useEffect(() => {
    setLocation({
      lat: typeof markerLat === "number" ? markerLat : undefined,
      lng: typeof markerLng === "number" ? markerLng : undefined,
    });
  }, [markerLat, markerLng]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <CardBody className="mapContainer rightContainer">
      <div
        style={{ position: "relative", overflow: "hidden", height: "400px" }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircleLoader color={"#3B90C8"} />
          </div>
        ) : (
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCScwQTSy0tjIeKghvHGirI2xxltC5pzAk&libraries=places`}
            loadingElement={
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>
            }
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            location={location}
          />
        )}
      </div>
    </CardBody>
  );
}
