import React from "react";
import { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Clients from "../../assets/svg/Clients_3.svg";
import Adminbar from "components/Sidebar/Adminbar";
// reactstrap components
import {
  UncontrolledTooltip,
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  // Input,
  // InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  // Modal,
} from "reactstrap";
import Sidebar from "components/Sidebar/Sidebar";

const AdminNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [modalSearch, setModalSearch] = React.useState(false);
  const [color, setColor] = React.useState("navbar-transparent");
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };
  const [depRight, setDepRight] = useState(true);
  const slideMove = () => {
    setDepRight((prevState) => !prevState);
  };

  // disable vertical scrolling when the sidebar is opened
  useEffect(() => {
    if (!depRight) {
      // Bloquer le défilement vertical
      document.body.style.overflowY = "hidden";
    } else {
      // Réactiver le défilement vertical
      document.body.style.overflowY = "auto";
    }

    // Réinitialiser lorsque le composant est démonté
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [depRight]);
  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.closest(".item-custom")) {
        // ATTENTION CELA FAIT 4 RENDER A VOIR POUR LE METTRE DANS UN OBJET SETTER QUI CONTIENDRA
        // LES 4 ARGUEMENTS MAIS il me semble que, quand la valeur d'une variable dans un objet change,
        // il ne se passe rien car c'est un objet
        setCollapseOpen(false);
        setDepRight((document.body.style.overflowY = "auto"));
        setColor("bg-white");
        setColor("navbar-transparent");
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);
  // this function is to open the Search modal
  // const toggleModalSearch = () => {
  //   setModalSearch(!modalSearch);
  // };
  return (
    <>
      <Navbar
        className={classNames("navbar-wrapper", {
          [color]: props.location.pathname.indexOf("full-screen-map") === -1,
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            {/*<div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={props.handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209599"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
              </div>*/}

            <div className={classNames("navbar-toggle d-inline")}>
              <button
                className={`navbar-toggler ${collapseOpen ? "toggled" : ""} ${
                  depRight ? "depRight" : "depLeft"
                }`}
                type="button"
                data-toggle="collapse"
                data-target="#navigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => {
                  toggleCollapse();
                  slideMove();
                }}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>

            <NavbarBrand href="#" onClick={(e) => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>

          <Collapse navbar isOpen={true}>
            {/* isOpen={collapseOpen} this Collapse must always be there */}
            {/* <InputGroup className="search-bar" tag="li">
                <Button
                  color="link"
                  data-target="#searchModal"
                  data-toggle="modal"
                  id="search-button"
                  onClick={toggleModalSearch}
                >
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Search</span>
                </Button>
              </InputGroup> */}
            {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                >
                  <div className="notification d-none d-lg-block d-xl-block" />
                  <i className="tim-icons icon-sound-wave" />
                  <p className="d-lg-none">Notifications</p>
                </DropdownToggle> */}
            {/* <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Mike John responded to your email
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      You have 5 more tasks
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Your friend Michael is in town
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Another notification
                    </DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Another one
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu> */}
            {/* </UncontrolledDropdown> */}
            <Sidebar />
            
          </Collapse>
        </Container>
      </Navbar>
      {/* <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal> */}
    </>
  );
};

export default AdminNavbar;
