import react, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-table-v6/react-table.css";
import ReactTable from "react-table-v6";
import "../../../../assets/css/custom.css";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { OLD_API_URL, oldGetApiConfig } from "../../Config/oldApiConfig.js";
import { getUserRoleId } from "views/composant/Config/apiConfig";
export default function ShowClientTab() {
  const nav = useNavigate();

  //TOKEN
  const token = localStorage.getItem("token");
  const userRoleId = getUserRoleId();

  const params = useParams();

  //API URL
  const API_URL = "https://acquaback.shenron.dev/api/";

  //AXIOS CONFIGURATION
  let CONFIG_ = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  //AXIOS REQUEST

  let showBoitierBelongToRequest = axios.get(
    API_URL + `getBoitierBelongToUser/${params.id}`,
    CONFIG_
  );

  // AXIOS OLD API REQUEST
  let showOldUserBoitier = axios
    .get(OLD_API_URL + `client/${params.id}/boitier`, oldGetApiConfig())
    .catch(() => {
      return { data: { data: [] } };
    });
  //DATA
  const [boitierBelongTo, setBoitierBelongTo] = useState([]);

  const localization = {
    previousText: "Précédent",
    nextText: "Suivant",
    pageText: "Page",
    ofText: "sur",
    rowsText: "lignes",
  };

  useEffect(() => {
    axios
      .all([showBoitierBelongToRequest, showOldUserBoitier])
      .then(
        axios.spread(function (res1, res2) {
          const ancienneData = res2?.data.data;
          const newData = res1?.data;
          console.log(ancienneData, "UserBoitier");
          console.log(newData, "newData");
          setBoitierBelongTo([...ancienneData, ...newData]);
        })
      )
      .catch((err) => {
        console.error(err);
      });
      // eslint-disable-next-line
  }, []);
  console.log(boitierBelongTo, "boitierBelongTo");
  const columns = [
    {
      Header: () => <div style={{ textAlign: "center" }}>Boitier</div>,
      accessor: (d) => `N° ${d.id}`,
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>LIEU</div>,
      accessor: (d) =>
        (d.city ? d.city : d.adresse.locality ? d.adresse.locality : " ") +
        (d.country
          ? ", " + d.country
          : d.adresse.country
          ? ", " + d.adresse.country
          : " "),
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>STATUS</div>,
      accessor: (d) => d.state || d.status || d.statut,
      Cell: ({ value }) => (
        <div
          style={{ color: value === 1 ? "green" : "red", textAlign: "center" }}
        >
          {value === 1 ? "actif" : "inactif"}
        </div>
      ),
    },
  ].map((col, i) => {
    col.id = `${i}`;
    return col;
  });

  const onRowClick = (rowInfo, state, column, instance) => {
    const isOld = rowInfo.noSerie >= 0 && rowInfo.noSerie <= 3500;
    console.log("noserie", rowInfo.noSerie);
    nav(`/showBoitier/${rowInfo.id}`, { state: { isOld, id: rowInfo.id } });
  };

  return (
    <>
      <Row className="CenterAll">
        <div className="containertitle">
          <h2 className="titlePage">LISTE BOITIER</h2>
        </div>
      </Row>
      <Row className="CenterAll">
        <Col lg="12" className="noPad">
          <Card className="mt-5">
            <Col lg="12" className="d-flex justify-content-center noPad">
              <Card>
                <CardBody className="noPad">
                  {boitierBelongTo ? (
                    <ReactTable
                      filterable
                      getTrProps={(state, rowInfo) => ({
                        onClick: () => onRowClick(rowInfo.original),
                      })}
                      value={boitierBelongTo?.id}
                      data={boitierBelongTo}
                      columns={columns}
                      defaultPageSize={10}
                      previousText={localization.previousText}
                      nextText={localization.nextText}
                      pageText={localization.pageText}
                      ofText={localization.ofText}
                      rowsText={localization.rowsText}
                    />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
}
