import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardBody, Button } from "reactstrap";
import "../../../assets/css/custom.css";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL, getApiConfig, getUserRoleId } from "../Config/apiConfig.js";

export default function BoitierLastAlert() {
  const nav = useNavigate();
  const params = useParams();
  const roleId = getUserRoleId();

  const [lastAlert, setLastAlert] = useState();

  useEffect(() => {
    axios
      .get(API_URL + `lastAlertByBoitier/${params.id}`, getApiConfig())
      .then((res) => {
        const filteredAlerts = res.data.filter((alert) => {
          const display = shouldDisplayAlert(alert);
          return display;
        });
        setLastAlert(filteredAlerts);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [params.id]);

  const addParamToUrlForAlert = (event) => {
    nav("/boitierAlert/" + params.id);
  };
  const shouldDisplayAlert = (alert) => {
    const alertsVisibility = {
      "Température interne trop élevé": [1, 2, 3, 4, 5],
      "Alerte humidité interne trop élévée": [1, 2, 3, 4, 5],
      "Capteur de vibration": [1, 2, 3, 4, 5],
      "Alerte le boitier a été ouvert par dessus": [1, 2, 3, 4, 5],
      "Alerte le boitier a été ouvert ou est exposé a une lumière trop forte": [
        1, 2, 3, 4,
      ],
      "Perte de connexion du boitier": [1, 2, 3, 4, 5],
      "Le boîtier a redémarré": [1, 2, 3, 4, 5],
      "Bobine débranché": [1, 2, 3, 4, 5],
    };

    return alertsVisibility[alert.title]?.includes(parseInt(roleId)) ?? true;
  };

  const getAlertColorClass = (title) => {
    const colorClassMap = {
      "Température interne trop élevé": "circle_form_orange",
      "Alerte humidité interne trop élévée": "circle_form_orange",
      "Capteur de vibration": "circle_form_orange",
      "Alerte le boitier a été ouvert par dessus": "circle_form_red",
      "Alerte le boitier a été ouvert ou est exposé a une lumière trop forte":
        "circle_form_red",
      "Perte de connexion du boitier": "circle_form_blue",
      "Le boîtier a redémarré": "circle_form_blue",
      "Bobine débranché": "circle_form_orange",
    };

    return colorClassMap[title] || "circle_form_red";
  };
  return (
    <>
      <CardBody className="mt-4 rightContainer">
        {lastAlert
          ? lastAlert.map((item, index) => (
              <Card
                key={index}
                className="shadow mb-5 bg-white rounded card-hover"
              >
                <CardBody>
                  <div className="ml-5 mr-5 d-flex">
                    <div
                      className={`${getAlertColorClass(
                        item.title
                      )} mr-4 flex-shrink-0`}
                    ></div>
                    <div className="d-flex flex-column">
                      {item.unite === "Booléen" ? (
                        <div>{item.title}</div>
                      ) : (
                        <div>
                          {item.title}: {item.value} {item.unite}
                        </div>
                      )}{" "}
                    </div>
                  </div>
                  <div className="float-right">
                    <div>{item.datetime}</div>
                  </div>
                </CardBody>
              </Card>
            ))
          : null}
        <div className="text-center">
          <Button type="submit" color="primary" onClick={addParamToUrlForAlert}>
            Voir plus d'alertes
          </Button>
        </div>
      </CardBody>
    </>
  );
}
